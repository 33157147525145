
import React, { useEffect, useCallback, useState } from 'react';
import axios from 'axios';
export default function SetPermissions() {
    const [errors, setErrors] = useState(false);
    const setPermissions = useCallback(async () => {
        try {
            await axios.put('/api/v1/user/update-role');
            await axios.get('/api/v1/user/update-token'); // this ensures that the token has the correct scopes before redirecting

            window.location.replace('/tire-recommendations');
        } catch (err) {
            console.error(err);
            setErrors(true);
        }
    }, [setErrors])

    useEffect(() => {
        setPermissions();
    }, [setPermissions]);

    return (
        <div className="flex flex-col w-screen h-screen">
            <header className="bg-blue flex items-center justify-center py-6">
                <img src="/goodyear-logo.svg" alt="Goodyear" width="128" />
            </header>
            <main className="flex flex-col items-center justify-center w-full h-full" style={{ "background": "url('/login-bg.jpg') no-repeat center / cover" }}>
                <div className="bg-blue-dark w-full h-8 mb-auto"></div>
                <div className="bg-transparent-white p-2">
                    <div className="bg-white px-[120px] py-6 flex flex-col justify-center items-center">
                        <img src="logo-TSN.png" alt="Tire & Service Network" />
                        <p className="font-sans-reg text-gray-dark my-4">{errors ? 'There was an error configuring your permissions.' : 'Setting up permissions'}</p>
                    </div>
                </div>
                <div className="bg-blue-dark w-full h-8 mt-auto"></div>
            </main>
        </div>
    );
}