import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

export default function Login() {
    const {
        loginWithRedirect, user
    } = useAuth0();

    useEffect(() => {
        if (typeof user != 'undefined') {
            window.location.replace('/tire-recommendations');
        }
    }, [user]);

    return (
        <div className="flex flex-col w-screen h-screen">
            <header className="bg-blue flex items-center justify-center py-6">
                <img src="/goodyear-logo.svg" alt="Goodyear" width="128" />
            </header>
            <main className="flex flex-col items-center justify-center w-full h-full" style={{ "background": "url('/login-bg.jpg') no-repeat center / cover" }}>
                <div className="bg-blue-dark w-full h-8 mb-auto"></div>
                <div className="bg-transparent-white p-2">
                    <div className="bg-white px-[120px] py-6 flex flex-col justify-center items-center">
                        <img src="logo-TSN.png" alt="Tire & Service Network" />
                        <p className="font-sans-reg text-gray-dark my-4">Click below to sign in:</p>
                        <Link onClick={loginWithRedirect} className="btn mb-4">Log in</Link>
                    </div>
                </div>
                <div className="bg-blue-dark w-full h-8 mt-auto"></div>
            </main>
        </div>
    )
}
