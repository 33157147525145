import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import InsideHeader from '../components/InsideHeader';
import UserAccordion from '../components/UserAccordion';
import axios from 'axios';
import { useToast } from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';

export default function Users() {
    const toast = useToast();
    const [stores, setStores] = useState([]);
    const [newUser, setNewUser] = useState({
        email: ''
    })
    const [companyUsers, setCompanyUsers] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const { user } = useAuth0();

    const getCompanyUsers = useCallback(async () => {
        try {
            const { data: companyData } = await axios.get(`/api/v1/user/company`);
            setCompanyUsers(companyData.data);
        } catch (err) {
            console.error(err);
        }
    }, [])

    const getCompanyDealers = useCallback(async () => {
        try {
            const { data } = await axios.get(`/api/v1/store/get`);
            setStores(data.data)
        } catch (err) {
            console.error(err.message);
        }
    }, [])

    const createCompanyUser = async () => {
        try {
            setIsSaving(true)
            await axios.post(`/api/v1/user/company`, {
                email: newUser.email
            }, {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8'
                }
            });
            getCompanyDealers();
            setIsSaving(false)
            toast({
                title: `New User Added`,
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
        } catch (err) {
            console.error(err.message);
            setIsSaving(false)
            toast({
                title: `Error`,
                description: `${err.response.data.errorMessages}`,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }

    const removeUser = async email => {
        try {
            await axios.delete(`/api/v1/user/company/`, {
                data: {
                    email: email
                }
            });
            getCompanyUsers();
            toast({
                title: `User Deleted`,
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
        } catch (err) {
            console.error(err)
            toast({
                title: `Error`,
                description: `${err.response.data.errorMessages}`,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }

    useEffect(() => {
        getCompanyDealers();
    }, [getCompanyDealers, user]);

    useEffect(() => {
        getCompanyUsers();
    }, [getCompanyUsers, stores])

    return (
        <>
            <InsideHeader heading="Store Users" imageSrc="/hero-tire_rec.png" imageSrcSet="/hero-tire_rec.png, /hero-tire_rec@2x.png 2x" imageAlt="Tires on racks" />
            <div className="px-6 py-10">
                <h2 className="text-2xl text-blue font-reg mb-4">Store Users</h2>
                <div className="flex mb-2">
                    <h4 className="w-1/3">STORE NAME</h4>
                    <h4 className="w-1/3">STORE NUMBER</h4>
                </div>
                {stores.map((store, index) =>
                    <UserAccordion storeName={store.dealer} storeNumber={store.dealerId} key={index} />
                )}
                {user?.custom_roles.includes("Owner") &&
                    <>
                    <div className="border border-1 my-6"></div>
                    <h2 className="text-2xl text-blue font-reg mb-4">Company Admins</h2>
                    <table className="w-full table-fixed text-sm font-sans-reg text-left border-separate border-spacing-y-2.5">
                        <thead className="text-gray-dark">
                            <tr>
                                <th>EMAIL</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {companyUsers.map(cuser =>
                                <tr className="even:bg-white odd:bg-blue-pale" key={cuser.userId}>
                                    <td className="p-2">{cuser.email}</td>
                                    <td className="p-2 text-right">
                                        {user.email !== cuser.email && <button className="btn-blue" onClick={() => removeUser(cuser.email)}><FontAwesomeIcon icon={faTimes} /></button>}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    {/*add new company admin*/}
                    <h2 className="text-blue text-2xl mb-4">Add a New Company Admin</h2>
                    <div className="flex items-center w-full">
                        <label className="mb-2 mr-2 flex flex-col flex-1 text-sm font-sans-reg font-bold text-gray-dark">EMAIL:
                            <input type="email" className="form-input border border-gray-300 shadow-inner" value={newUser.email} onChange={e => setNewUser({ ...newUser, email: e.target.value })} />
                        </label>
                        <button className="btn translate-y-2" onClick={() => createCompanyUser()}>{isSaving ? 'Saving...' : 'CREATE'}</button>
                    </div>
                    </>
                }
            </div>
        </>
    );
}
