import React, { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';

export default function PriceAdjustmentCard(props) {
    const [retailPerTire, setRetailPerTire] = useState();
    const [invoiceGPPercent, setInvoiceGPPercent] = useState(0);
    const [invoiceGPAmount, setInvoiceGPAmount] = useState(0);
    const [mostProfitablePriceSetting, setMostProfitablePriceSetting] = useState('');

    useEffect(() => {
        setRetailPerTire(props.retailPerTire)
    }, [props.retailPerTire]);

    useEffect(() => {
        if (props.adjustment) {
            if (props.priceSetting === 'MAP' && props.mapPrice !== null) {
                setRetailPerTire(props.mapPrice.toFixed(2));
                setInvoiceGPPercent((((props.mapPrice - props.dealerCost) / props.dealerCost) * 100).toFixed(2));
                setInvoiceGPAmount((props.mapPrice - props.dealerCost).toFixed(2));
            }
            if (props.priceSetting === 'PMP' && props.pmpPrice !== null) {
                setRetailPerTire(props.pmpPrice.toFixed(2));
                setInvoiceGPPercent((((props.pmpPrice - props.dealerCost) / props.dealerCost) * 100).toFixed(2));
                setInvoiceGPAmount((props.pmpPrice - props.dealerCost).toFixed(2));
            }
            if (props.priceSetting === 'R-Adj') {
                if (props.companyPriceSettings.length) {
                    props.companyPriceSettings.forEach(setting => {
                        if ((setting.minCost === 0 && setting.maxCost === 0) || (props.dealerCost >= setting.minCost && props.dealerCost <= setting.maxCost)) {
                            if (setting.priceAdjustmentPercent > 0) {
                                // set retail to cost + priceAdjustmenPercent + amount needed to make it = priceAdjustmenPercentMin
                                setRetailPerTire((props.dealerCost + ((props.dealerCost * (setting.priceAdjustmentPercent / 100)) >= setting.priceAdjustmentPercentMin ? (props.dealerCost * (setting.priceAdjustmentPercent / 100)) : setting.priceAdjustmentPercentMin)).toFixed(2))
                                setInvoiceGPPercent(setting.priceAdjustmentPercent);
                                setInvoiceGPAmount(((props.dealerCost + ((props.dealerCost * setting.priceAdjustmentPercent) / 100)) - props.dealerCost).toFixed(2))
                            } else if (setting.priceAdjustmentPercent === 0 && setting.priceAdjustmentDollars > 0) {
                                setRetailPerTire(props.dealerCost + setting.priceAdjustmentDollars)
                                setInvoiceGPPercent(((((props.dealerCost + setting.priceAdjustmentDollars) - props.dealerCost) / props.dealerCost) * 100).toFixed(2));
                                setInvoiceGPAmount(setting.priceAdjustmentDollars);
                            }
                        }
                    })
                } else {
                    setInvoiceGPPercent((((props.retailPerTire - props.dealerCost) / props.dealerCost) * 100).toFixed(2));
                    setInvoiceGPAmount((props.retailPerTire - props.dealerCost).toFixed(2));
                }
            }
            if (props.priceSetting === 'USER') {
                setInvoiceGPPercent((((props.retailPerTire - props.dealerCost) / props.dealerCost) * 100).toFixed(2));
                setInvoiceGPAmount((props.retailPerTire - props.dealerCost).toFixed(2));
            }
        } else {
            // show most profitable retail per tire with calcualted GP % and $ amounts
            if (props.pmpPrice) { // if PMP show PMP
                setMostProfitablePriceSetting('PMP');
                setRetailPerTire(props.pmpPrice.toFixed(2));
                setInvoiceGPPercent((((props.pmpPrice - props.dealerCost) / props.dealerCost) * 100).toFixed(2));
                setInvoiceGPAmount((props.pmpPrice - props.dealerCost).toFixed(2));
            } else if (props.pmpPrice === null && props.mapPrice) { // else if no PMP show MAP
                setMostProfitablePriceSetting('MAP');
                setRetailPerTire(props.mapPrice.toFixed(2));
                setInvoiceGPPercent((((props.mapPrice - props.dealerCost) / props.dealerCost) * 100).toFixed(2));
                setInvoiceGPAmount((props.mapPrice - props.dealerCost).toFixed(2));
            } else { // else retail
                setMostProfitablePriceSetting('R-Adj');
                if (props.companyPriceSettings.length) {
                    props.companyPriceSettings.forEach(setting => {
                        if ((setting.minCost === 0 && setting.maxCost === 0) || (props.dealerCost >= setting.minCost && props.dealerCost <= setting.maxCost)) {
                            if (setting.priceAdjustmentPercent > 0) {
                                // set retail to cost + priceAdjustmenPercent + amount needed to make it = priceAdjustmenPercentMin
                                setRetailPerTire((props.dealerCost + ((props.dealerCost * (setting.priceAdjustmentPercent / 100)) >= setting.priceAdjustmentPercentMin ? (props.dealerCost * (setting.priceAdjustmentPercent / 100)) : setting.priceAdjustmentPercentMin)).toFixed(2))
                                setInvoiceGPPercent(setting.priceAdjustmentPercent);
                                setInvoiceGPAmount(((props.dealerCost + ((props.dealerCost * setting.priceAdjustmentPercent) / 100)) - props.dealerCost).toFixed(2))
                            } else if (setting.priceAdjustmentPercent === 0 && setting.priceAdjustmentDollars > 0) {
                                setRetailPerTire(props.dealerCost + setting.priceAdjustmentDollars)
                                setInvoiceGPPercent(((((props.dealerCost + setting.priceAdjustmentDollars) - props.dealerCost) / props.dealerCost) * 100).toFixed(2));
                                setInvoiceGPAmount(setting.priceAdjustmentDollars);
                            }
                        }
                    })
                } else {
                    setInvoiceGPPercent((((props.retailPerTire - props.dealerCost) / props.dealerCost) * 100).toFixed(2));
                    setInvoiceGPAmount((props.retailPerTire - props.dealerCost).toFixed(2));
                }
            }
        }
    }, [props.adjustment, props.priceSetting, props.retailPerTire, props.dealerCost, props.mapPrice, props.pmpPrice, props.companyPriceSettings])

    const updateRetail = retail => {
        props.updateRetailPerTire(props.productCode, retail);
    };
    // eslint-disable-next-line
    const debounceUpdate = useCallback(debounce(updateRetail, 1000), []);

    return (
        <div className={`flex w-full my-2 px-3 py-2 border border-gray shadow rounded`} >
            <div className="w-2/3">
                <h3 className="flex items-center text-blue-dark font-medium uppercase text-[24px]">{props.brand} {props.name}</h3>
                <ul className="flex text-blue-dark">
                    <li>{props.size}<span className="text-gray px-2">/</span></li>
                    <li>{props.productCode}  <span className="text-gray px-2">/</span></li>
                    <li>{props.type}</li>
                </ul>
                <table className="table-auto w-full text-sm mt-2">
                    <thead>
                        <tr>
                            <th className="text-left font-normal text-lg p-1">Price Setting</th>
                            <th className="text-left font-normal text-lg p-1">$Retail Per Tire</th>
                            <th className="text-left font-normal text-lg p-1 bg-blue-pale">Invoice GP %</th>
                            <th className="text-left font-normal text-lg p-1">Invoice GP $</th>
                            <th className="text-left font-normal text-lg p-1 bg-blue-pale">Dealer Cost</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="align-bottom">
                                <div className="flex px-1 ">
                                    <label className={`flex flex-col justify-end leading-4 items-center mr-4 ${!props.adjustment || props.mapPrice === null ? 'text-gray-inactive' : ''}`}>
                                        <input type="radio" name={`${props.productCode}-priceSetting`} value="MAP" disabled={!props.adjustment || props.mapPrice === null} onChange={e => props.updatePriceSetting(props.productCode, e.target.value, props.rank)} className={`${!props.adjustment || props.mapPrice === null ? 'border-gray-inactive' : ''}`} checked={props.priceSetting ? props.priceSetting === 'MAP' : mostProfitablePriceSetting === 'MAP'} />
                                        MAP
                                    </label>
                                    <label className={`flex flex-col justify-end leading-4 items-center mr-4 ${!props.adjustment || props.pmpPrice === null ? 'text-gray-inactive' : ''}`}>
                                        <input type="radio" name={`${props.productCode}-props.priceSetting`} value="PMP" disabled={!props.adjustment || props.pmpPrice === null} onChange={e => props.updatePriceSetting(props.productCode, e.target.value, props.rank)} className={`${!props.adjustment || props.pmpPrice === null ? 'border-gray-inactive' : ''}`} checked={props.priceSetting ? props.priceSetting === 'PMP' : mostProfitablePriceSetting === 'PMP'} />
                                        Market Price
                                    </label>
                                    <label className={`flex flex-col justify-end leading-4 items-center mr-4 ${!props.adjustment ? 'text-gray-inactive' : ''}`}>
                                        <input type="radio" name={`${props.productCode}-props.priceSetting`} value="R-Adj" disabled={!props.adjustment} onChange={e => props.updatePriceSetting(props.productCode, e.target.value, props.rank)} className={`${!props.adjustment ? 'border-gray-inactive' : ''}`} checked={props.priceSetting ? props.priceSetting === 'R-Adj' : mostProfitablePriceSetting === 'R-Adj'} />
                                        Retail
                                    </label>
                                    <label className={`flex flex-col justify-end leading-4 items-center ${!props.adjustment ? 'text-gray-inactive' : ''}`}>
                                        <input type="radio" name={`${props.productCode}-props.priceSetting`} value="USER" disabled={!props.adjustment} onChange={e => props.updatePriceSetting(props.productCode, e.target.value, props.rank)} className={`${!props.adjustment ? 'border-gray-inactive' : ''}`} checked={props.priceSetting === 'USER'} />
                                        User
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div className={`flex items-center ${!props.adjustment || props.priceSetting !== 'USER' ? 'form-input--disabled' : ''}`}>
                                    <span className="mr-1">$</span><input type="number" name={`${props.productCode}retailPerTire`} value={retailPerTire} placeholder="0.00" className="w-[100px]" disabled={!props.adjustment || props.priceSetting !== 'USER'} onChange={e => { setRetailPerTire(e.target.value); debounceUpdate(e.target.value); }} />
                                </div>
                            </td>
                            <td className="p-1 bg-blue-pale">
                                <span className="font-sans-reg">{invoiceGPPercent}</span>
                            </td>
                            <td className="p-1">
                                <span className="font-sans-reg">{invoiceGPAmount}</span>
                            </td>
                            <td className="p-1 bg-blue-pale">
                                <span className="font-sans-reg">{props.dealerCost}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="border-l mt-6 mx-3"></div>
            <div className="w-1/3 flex flex-col justify-between">
                <div className="ml-auto">
                    <div className="flex justify-end h-[24px]">
                        <label className="mr-4">*Default to MAP or most profitable <input type="checkbox" name="brandSetting" className="ml-1" checked={!props.adjustment || props.adjustment.priceSetting === 'DEF'} onChange={() => props.updateMostProfitable(props.productCode, props.adjustment, props.rank)} /></label>
                    </div>
                </div>
                <div className="flex">
                    <div>
                        <h6 className="text-blue-dark text-lg mt-auto">PROGRAM DOLLARS</h6>
                        <div className="my-2 flex items-center">
                            <a href={props.pdf} target="_blank" rel="noreferrer" className="btn-blue">View PDF</a>
                        </div>
                    </div>
                    <div className="flex flex-col ml-auto">
                        <label>Sell to</label>
                        <select value={props.rank === null ? '' : props.rank} onChange={e => props.updateRank(props.productCode, props.adjustment ? props.adjustment.price : 0.0, props.priceSetting ? props.priceSetting : 'DEF', e.target.value)}>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="">No Rank</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    );
}