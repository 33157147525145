import React, { useState, useEffect, useCallback } from 'react';
import InsideHeader from '../components/InsideHeader';
import PriceAdjustmentCard from '../components/PriceAdjustmentCard';
import axios from 'axios';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useToast } from '@chakra-ui/react';
import { useOutletContext } from "react-router-dom";
import { debounce } from 'lodash';

export default function PriceAdjustments() {
    const toast = useToast();
    const [isLoading, setIsLoading] = useState(false);
    const [resources, setResources] = useState([]);
    const [stores, setStores] = useState([]);
    const [selectedStore, setSelectedStore] = useState("");
    const [initialTires, setInitialTires] = useState([]);
    const [tires, setTires] = useState([])
    const [companyPriceSettings, setCompanyPriceSettings] = useState();
    const [searchInput, setSearchInput] = useState('');
    const [search, setSearch] = useState('')
    const [storeView] = useOutletContext();
    const tireBrands = [];
    initialTires.map(tire => {
        if (tireBrands.indexOf(tire.tire.brand) === -1) {
            tireBrands.push(tire.tire.brand);
        }
        return tire;
    })

    const [activeFilters, setActiveFilters] = useState({
        category: [],
        brand: []
    })

    const onFilter = (e, filterType) => {
        if (activeFilters[filterType].indexOf(e.target.value) === -1) {
            setActiveFilters({ ...activeFilters, ...activeFilters[filterType].push(e.target.value) });
        } else {
            setActiveFilters({ ...activeFilters, [filterType]: activeFilters[filterType].filter(value => value !== e.target.value) })
        }
    }

    // checkbox filters
    const applyFilters = useCallback(tires => {
        return tires.filter(tire => {
            let filters = false;
            for (let key in activeFilters) {
                if (activeFilters[key].length > 0) {
                    filters = true;
                }
            }
            if (filters) {
                let pass = false;
                for (let key in activeFilters) {
                    if (activeFilters[key].length > 0) {
                        if (activeFilters[key].includes(tire.tire[key])) {
                            pass = true;
                        } else {
                            pass = false;
                            return false;
                        }
                    } else {
                        continue;
                    }
                }
                return pass;
            } else {
                return tire;
            }
        })
    }, [activeFilters])

    // search
    const updateSearch = term => {
        setSearch(term);
    }
    // eslint-disable-next-line
    const debounceSearch = useCallback(debounce(updateSearch, 1000), []);

    const applySearchFilter = useCallback(tires => {
        // set up regex to filter numbers only or alpha-numeric depending on the search term
        let re;
        if (search.match(/[0-9]+$/g)) {
            re = /[^0-9]/g;
        } else {
            re = /[^a-zA-Z0-9]/g;
        }
        return tires.filter(tire => tire.tire.name.replace(re, '').toLowerCase().includes(search.replace(re, '').toLowerCase()) || tire.tire.size.replace(re, '').toLowerCase().includes(search.replace(re, '').toLowerCase()) || tire.tire.productCode.replace(re, '').toLowerCase().includes(search.replace(re, '').toLowerCase()))
    }, [search])

    const updateMostProfitable = (productCode, hasAdjustment, rank) => {
        if (rank === '') {
            rank = null;
        }
        setTires(tires.map(tire => {
            if (tire.tire.productCode === productCode) {
                if (hasAdjustment && rank === null) {
                    deletePriceAdjustments(productCode);
                }
                else if (hasAdjustment && rank !== null) {
                    updatePriceSetting(productCode, 'DEF', rank);
                }
                else if (rank === null) {
                    updatePriceSetting(productCode, 'R-Adj');
                }
            }
            return tire;
        }))
    }

    const updateRank = async (productCode, price, priceSetting, rank) => {
        if (rank === '') {
            rank = null;
        }
        try {
            await axios.post('/api/v1/tire-adjustments',
                {
                    DealerId: selectedStore,
                    ProductCode: productCode,
                    Price: price,
                    PriceSetting: priceSetting,
                    Rank: rank
                },
                {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8'
                    },
                });
            getPriceAdjustments();
            toast({
                title: `Tire Rank Updated`,
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
        } catch (err) {
            console.error(err.message);
            toast({
                title: `Error`,
                description: `${err.response.data.errorMessages}`,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }

    const updatePriceSetting = (productCode, priceSetting, rank) => {
        setTires(tires.map(tire => {
            if (tire.tire.productCode === productCode) {
                switch (priceSetting) {
                    case 'MAP':
                        postPriceAdjustment(productCode, tire.mapPrice, priceSetting, rank);
                        break;
                    case 'PMP':
                        postPriceAdjustment(productCode, tire.pmpPrice, priceSetting, rank);
                        break;
                    case 'R-Adj':
                        postPriceAdjustment(productCode, tire.dealerCost, priceSetting, rank);
                        break;
                    case 'USER':
                        postPriceAdjustment(productCode, 0, priceSetting, rank);
                        break;
                    case 'DEF':
                        postPriceAdjustment(productCode, 0, priceSetting, rank);
                        break;
                    default:
                        break;
                }
            }
            return tire;
        }))
    }
    const updateRetailPerTire = (productCode, retailPerTire) => {
        setTires(tires.map(tire => {
            if (tire.tire.productCode === productCode) {
                tire.retailPerTire = retailPerTire;
                postPriceAdjustment(productCode, retailPerTire, 'USER');
            }
            return tire;
        }))
    }

    const getPriceAdjustments = useCallback(async () => {
        try {
            const { data } = await axios.get(`/api/v1/tire-adjustments?dealer=${selectedStore}`);
            setInitialTires(data.data.map(tire => {
                return {
                    ...tire,
                    priceSetting: "",
                    retailPerTire: 0
                }
            }));
        } catch (err) {
            console.error(err.message);
            toast({
                title: `Error`,
                description: `${err.response.data.errorMessages}`,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }

    }, [toast, selectedStore])

    const postPriceAdjustment = async (productCode, price, priceSetting, rank) => {
        try {
            await axios.post('/api/v1/tire-adjustments',
                {
                    DealerId: selectedStore,
                    ProductCode: productCode,
                    Price: price,
                    PriceSetting: priceSetting,
                    Rank: rank
                },
                {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8'
                    },
                });
            getPriceAdjustments();
            toast({
                title: `Price Settings Updated`,
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
        } catch (err) {
            console.error(err.message);
            toast({
                title: `Error`,
                description: `${err.response.data.errorMessages}`,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }

    const deletePriceAdjustments = async (productCode) => {
        try {
            await axios.delete(`api/v1/tire-adjustments?dealer=${selectedStore}&productcode=${productCode}`, {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8'
                },
            });
            getPriceAdjustments();
        } catch (err) {
            console.error(err.message);
            toast({
                title: `Error`,
                description: `${err.response.data.errorMessages}`,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }

    const getCompanyPriceSettings = useCallback(async () => {
        try {
            setIsLoading(true);
            const { data } = await axios.get(`/api/v1/company-price-settings`);
            setCompanyPriceSettings(data.data);
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            console.error(err.message);
            toast({
                title: `Error`,
                description: `${err.response.data.errorMessages}`,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }, [toast])
    

    const updateSelectedStore = useCallback(async (val) => {
        setSelectedStore(val)
    }, [setSelectedStore])


    const getResources = useCallback(async () => {
        try {
            const { data } = await axios.get(`/api/v1/store/resources/${selectedStore}`);
            setResources(data.data);
        } catch (err) {
            console.error(err.message);
        }
    }, [selectedStore]);
    
    const getCompanyDealers = useCallback(async () => {
        try {
            const { data } = await axios.get(`/api/v1/store/get`);
            setStores(data.data)
            if (data.data.length === 1) {
                setSelectedStore(data.data[0].dealerId)
            }
        } catch (err) {
            console.error(err.message);
        }
    }, [setSelectedStore])

    useEffect(() => {
        getCompanyDealers()
    }, [getCompanyDealers])

    useEffect(() => {
        if (selectedStore !== "") {
            getResources()
            getPriceAdjustments()
            getCompanyPriceSettings()
        }
    }, [selectedStore, getResources, getPriceAdjustments, getCompanyPriceSettings])

    useEffect(() => {
        if (selectedStore !== "") {
            // apply filters
            let results = applyFilters(initialTires);
            // apply search
            results = applySearchFilter(results);

            setTires(results);
        }
    }, [applyFilters, applySearchFilter, initialTires, selectedStore])

    return (
        <div className="flex h-full">
            <ul className={`${storeView ? 'bg-gray-light' : 'bg-blue-pale'} p-3 h-full min-w-[180px] max-w-[190px]`}>
                <li className="flex flex-col mb-2">
                    <h6 className="text-[13px] mb-2">BRAND:</h6>
                    {tireBrands.map((brand, index) =>
                        <label className="flex items-center font-sans-reg text-gray-500 text-sm pl-4 mb-2" key={index}>
                            <input type="checkbox" className="mr-2" value={brand} onChange={e => onFilter(e, 'brand')} />
                            {brand}
                        </label>
                    )}
                </li>
            </ul>
            <div className="relative w-screen">
                <InsideHeader heading="Price Settings" imageSrc="/hero-auto-service.png" imageSrcSet="/hero-auto-service.png, /hero-auto-service@2x.png 2x" imageAlt="Tire technician checking tire" />
                {stores.length > 1 &&
                    <div className="p-6">
                        <h3 className="font-sans-reg mb-7">SELECT YOUR STORE:</h3>
                        <label className="flex items-center">
                            <select id="type" onChange={e => updateSelectedStore(e.target.value)} value={selectedStore}>
                                <option value="" key="select-store">Select a Store</option>
                                {stores.map((store, index) =>
                                    <option value={store.dealerId} key={store.dealerId}>{store.dealer}</option>
                                )}
                            </select>
                        </label>
                    </div>
                }
                {selectedStore !== "" &&
                    <div className="px-6 py-10">
                        <p className="mb-8 text-lg">Adjust individual tire price settings here. Tires are shown with options to set prices by MAP & PMP. Search by part numbers or sizes below.</p>
                        <div className="bg-blue-pale mb-4 p-3 flex items-center space-between">
                            <div className="flex">
                                <input type="text" className="form-input border border-gray shadow-inner w-[275px] mr-2" value={searchInput} placeholder="Enter a keyword to search" onChange={e => { setSearchInput(e.target.value); debounceSearch(e.target.value) }} />
                                {/*<button className="btn-blue text-[18px]" onClick={onSearch}><FontAwesomeIcon icon={faSearch} className="mr-1" />Search</button>*/}
                            </div>
                        </div>
                        {isLoading
                            ?
                            <span className="text-lg">Loading ...</span>
                            :
                            <div className="h-screen">
                                <AutoSizer>
                                    {({ height, width }) => (
                                        <List
                                            height={height}
                                            width={width}
                                            itemData={tires}
                                            itemCount={tires.length}
                                            itemSize={200}
                                        >
                                            {({ data, index, style }) => {
                                                return <div style={style}>
                                                    <PriceAdjustmentCard
                                                        adjustment={data[index].adjustment}
                                                        brand={data[index].tire.brand}
                                                        dealerCost={data[index].dealerCost}
                                                        companyPriceSettings={companyPriceSettings}
                                                        name={data[index].tire.name}
                                                        mapPrice={data[index].mapPrice}
                                                        outOfStock={data[index].tire.outOfStock}
                                                        pdf={resources[data[index].tire.brand][0]?.uri}
                                                        pmpPrice={data[index].pmpPrice}
                                                        priceSetting={data[index].adjustment ? data[index].adjustment.priceSetting : ''}
                                                        productCode={data[index].tire.productCode}
                                                        rank={data[index].adjustment ? data[index].adjustment.rank : ''}
                                                        retailPerTire={data[index].adjustment ? data[index].adjustment.price : data[index].retailPerTire}
                                                        updateMostProfitable={updateMostProfitable}
                                                        updatePriceSetting={updatePriceSetting}
                                                        updateRetailPerTire={updateRetailPerTire}
                                                        updateRank={updateRank}
                                                        size={data[index].tire.size}
                                                        type={data[index].tire.category}
                                                        key={index} />
                                                </div>
                                            }}
                                        </List>
                                    )}
                                </AutoSizer>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    );
}
