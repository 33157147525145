import React, { useState, useEffect, useCallback } from 'react';
import InsideHeader from '../components/InsideHeader';
import StoreAccordion from '../components/StoreAccordion';
import axios from 'axios';
import { useToast } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

export default function StoreSettings() {
    const toast = useToast();
    const [stores, setStores] = useState([]);
    const [newStore, setNewStore] = useState({
        dealer: '',
        dealerId: '',
    })
    const [files, setFiles] = useState([]);
    const [filesWD, setFilesWD] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [warehouses, setWarehouses] = useState();
    const { user } = useAuth0();

    const getFiles = useCallback(async () => {
        try {
            const { data: companyData } = await axios.get(`/api/v1/import/all`);
            setFiles(companyData.data);
        } catch (err) {
            console.error(err);
        }
    }, [])

    const getFilesWD = useCallback(async () => {
        let dealerIds = '';
        for (let store of stores) {
            dealerIds += store.dealerId + ',';
        }
        try {
            const { data: dealerData } = await axios.get(`/api/v1/import/wd?ids=${dealerIds}`);
            setFilesWD(dealerData.data);
        } catch (err) {
            console.error(err);
        }
    }, [stores])

    const getStores = useCallback(async () => {
        setStores([]);
        try {
            const { data } = await axios.get(`/api/v1/store/get`);
            setStores(data.data)
        } catch (err) {
            console.error(err.message);
        }
    }, [])

    const createStore = async () => {
        try {
            setIsSaving(true)
            await axios.post(`/api/v1/store-settings`, {
                DealerId: newStore.dealerId,
                Dealer: newStore.dealer
            }, {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8'
                }
            });
            getStores();
            setIsSaving(false)
            toast({
                title: `New Store Added`,
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
        } catch (err) {
            console.error(err.message);
            setIsSaving(false)
            toast({
                title: `Error`,
                description: `${err.response.data.errorMessages}`,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }

    const getWarehouses = useCallback(async () => {
        try {
            const { data } = await axios.get(`/api/v1/store-settings/warehouses`);
            setWarehouses(data.data);
            //console.log(data.data);
        } catch (err) {
            console.error(err);
            toast({
                title: `Error`,
                description: `${err.response.data.errorMessages}`,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }, [toast])

    useEffect(() => {
        getStores();
        getWarehouses();
    }, [getStores, getWarehouses]);

    useEffect(() => {
        getFiles();
        getFilesWD();
    }, [getFiles, getFilesWD, stores])

    return (
        <>
            <InsideHeader heading="Store Settings" imageSrc="/hero-tire_rec.png" imageSrcSet="/hero-tire_rec.png, /hero-tire_rec@2x.png 2x" imageAlt="Tires on racks" />
            <div className="px-6 py-10">
                <h2 className="text-2xl text-blue font-reg mb-4">Master Settings for All Stores</h2>
                <div className="flex mb-12 pb-12 border-blue-dark border-b-2">
                    <Link to="/file-uploads" className="btn-blue text-xl mr-4">ADJUST DISTRIBUTOR PRICING OR INVENTORY</Link>
                    {user?.custom_roles.includes("Owner") && <Link to="/price-settings" className="btn-blue text-xl">ADJUST MARK-UPS</Link>}
                </div>
                <h2 className="text-2xl text-blue font-reg mb-4">Individual Store Settings</h2>
                <div className="flex mb-2">
                    <h4 className="w-1/3">STORE NAME</h4>
                    <h4 className="w-1/3">STORE NUMBER</h4>
                </div>
                {stores.map((store, index) =>
                    <StoreAccordion storeName={store.dealer} storeNumber={store.dealerId} key={index} files={files} filesWD={filesWD[store.dealerId]} warehouses={warehouses} getStores={getStores} />
                )}
                <div className="border border-1 my-6"></div>
                {/*add new store*/}
                {user?.custom_roles.includes("Owner") && <h2 className="text-blue text-2xl mb-4">Add a New Store</h2>}
                {user?.custom_roles.includes("Owner") && <div className="flex items-center w-full">
                    <label className="mb-2 mr-2 flex flex-col flex-1 text-sm font-sans-reg font-bold text-gray-dark">STORE NAME:
                        <input type="text" className="form-input border border-gray-300 shadow-inner" value={newStore.dealer} onChange={e => setNewStore({ ...newStore, dealer: e.target.value })} />
                    </label>
                    <label className="mb-2 mr-2 flex flex-col flex-1 text-sm font-sans-reg font-bold text-gray-dark">STORE NUMBER:
                        <input type="text" className="form-input border border-gray-300 shadow-inner" value={newStore.dealerId} onChange={e => setNewStore({ ...newStore, dealerId: e.target.value })} />
                    </label>
                    <button className="btn translate-y-2" onClick={() => createStore()}>{isSaving ? 'Saving...' : 'CREATE'}</button>
                </div>}
            </div>
        </>
    );
}
