import React from 'react';

function ReactComponent(props) {
    return (
        <div className="flex items-center pl-6 leading-none overflow-hidden">
            <h1 className="text-gray-dark text-[36px]">{props.heading}</h1>
            <img src={`${props.imageSrc}`} srcSet={`${props.imageSrcSet}`} alt={`${props.imageAlt}`} className="flex-grow" />
        </div>
    );
}

export default ReactComponent;