import React, { useEffect, useState, useCallback } from 'react';
import InsideHeader from '../components/InsideHeader';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useToast } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSpinner } from '@fortawesome/free-solid-svg-icons';

export default function GrossProfitMarkUp() {
    const toast = useToast();
    const [priceSettings, setPriceSettings] = useState([])
    const [newPriceSetting, setNewPriceSetting] = useState({
        costRange: null,
        adjustmentMethod: null,
        minCost: 0,
        maxCost: 0,
        priceAdjustmentPercent: 0,
        priceAdjustmentPercentMin: 0,
        priceAdjustmentDollars: 0
    })
    const [saving, setSaving] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [isEditing, setIsEditing] = useState(false)

    const onEdit = setting => {
        setModalOpen(true)
        setIsEditing(true);
        // set newPriceSetting with values of the setting to edit and select the correct radio buttons
        setNewPriceSetting(
            {
                ...setting,
                costRange: setting.maxCost !== 0 ? true : false,
                adjustmentMethod: setting.priceAdjustmentPercent > 0 ? 'percent' : 'dollars'
            });
    }

    const getCompanyPriceSettings = useCallback(async () => {
        try {
            const { data } = await axios.get('/api/v1/company-price-settings');
            setPriceSettings(data.data);
        } catch (err) {
            console.error(err);
            toast({
                title: `Error`,
                description: `${err.response.data.errorMessages}`,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }, [toast])

    const postPriceSetting = async e => {
        e.preventDefault();
        try {
            setSaving(true);
            await axios.post('/api/v1/company-price-settings',
                {
                    "MinCost": newPriceSetting.minCost,
                    "MaxCost": newPriceSetting.maxCost,
                    "PriceAdjustmentPercent": newPriceSetting.priceAdjustmentPercent,
                    "PriceAdjustmentPercentMin": newPriceSetting.priceAdjustmentPercentMin,
                    "PriceAdjustmentDollars": newPriceSetting.priceAdjustmentDollars
                },
                {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8'
                    },
                }
            )
            setSaving(false);
            toast({
                title: `Price Settings Updated`,
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
        } catch (err) {
            setSaving(false);
            toast({
                title: `Error`,
                description: `${err.response.data.errorMessages}`,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
        getCompanyPriceSettings();
    }

    const updatePriceSetting = async e => {
        e.preventDefault();
        try {
            setSaving(true);
            await axios.put('/api/v1/company-price-settings',
                {
                    "CompanyPriceSettingId": newPriceSetting.companyPriceSettingId,
                    "MinCost": newPriceSetting.minCost,
                    "MaxCost": newPriceSetting.maxCost,
                    "PriceAdjustmentPercent": newPriceSetting.priceAdjustmentPercent,
                    "PriceAdjustmentPercentMin": newPriceSetting.priceAdjustmentPercentMin,
                    "PriceAdjustmentDollars": newPriceSetting.priceAdjustmentDollars
                },
                {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                    },
                }
            )
            setSaving(false);
            toast({
                title: `Price Settings Updated`,
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
        } catch (err) {
            setSaving(false);
            toast({
                title: `Error`,
                description: `${err.response.data.errorMessages}`,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
        getCompanyPriceSettings();
    }

    const deletePriceSetting = async settingId => {
        try {
            setSaving(true);
            await axios.delete(`/api/v1/company-price-settings/${settingId}`)
            toast({
                title: `Price Setting Deleted`,
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
            setSaving(false)
        } catch (err) {
            setSaving(false);
            toast({
                title: `Error`,
                description: `${err.response.data.errorMessages}`,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
        getCompanyPriceSettings();
    }

    useEffect(() => {
        getCompanyPriceSettings();
    }, [getCompanyPriceSettings])

    return (
        <div className="relative">
            <InsideHeader heading="Gross Profit & Mark-Up" imageSrc="/hero-pricesettings.png" imageSrcSet="/hero-pricesettings.png, /hero-pricesettings@2x.png 2x" imageAlt="Tire technician checking tire" />
            <div className="px-6 py-10">
                <div className="flex justify-between">
                    <h2 className="text-2xl text-blue">Adjustments for Mark-Ups</h2>
                    <button className="btn-blue ml-auto" onClick={() => setModalOpen(true)}>Add Adjustment</button>
                </div>
                {priceSettings.length ?
                    <table className="table-auto w-full mb-6">
                        <thead>
                            <tr>
                                <th className="text-left p-4 text-blue-link font-light">Cost Range</th>
                                <th className="text-left p-4 text-blue-link font-light">Mark-up Method</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody className="text-gray-dark">
                            {priceSettings.map((setting, index) =>
                                <tr className="odd:bg-blue-pale" key={index}>
                                    <td className="px-4 py-2">${setting.minCost}-${setting.maxCost}</td>
                                    <td>Mark up by {setting.priceAdjustmentPercent > 0 ? `${setting.priceAdjustmentPercent}%` : `$${setting.priceAdjustmentDollars}`} {setting.priceAdjustmentPercent > 0 ? 'with minimum of $' + setting.priceAdjustmentPercentMin : ''}</td>
                                    <td><Link className="text-blue-link" onClick={() => onEdit(setting)}>EDIT</Link>
                                        <button className="text-blue-link ml-4" onClick={() => deletePriceSetting(setting.companyPriceSettingId)}><FontAwesomeIcon icon={saving ? faSpinner : faTimes} className={saving ? 'spinner ml-2' : ''} /></button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    : <h3>No price adjustment settings</h3>}
                {modalOpen ?
                    <div className="absolute z-10 top-8 left-1/2 translate-x-[-50%] w-[97%] bg-white p-6 rounded shadow-lg">
                        <div className="flex justify-between items-center mb-12">
                            <h1 className="text-gray-dark text-[36px]">{isEditing ? 'Edit Adjustment' : 'Add Adjustment'}</h1>
                            <button className="btn-blue" onClick={() => { setModalOpen(!modalOpen); setNewPriceSetting({}) }}><FontAwesomeIcon icon={faTimes} /></button>
                        </div>
                        <form className="flex flex-col" onSubmit={isEditing ? updatePriceSetting : postPriceSetting}>
                            <h6 className="text-blue-link mb-4">Cost Range</h6>
                            <label className="mb-4">
                                <input type="radio" name="costRange" checked={!newPriceSetting.costRange} className="mr-2" onChange={() => setNewPriceSetting({ ...newPriceSetting, costRange: false, minCost: 0, maxCost: 0 })} />
                                No Cost Range
                            </label>
                            <label className="mb-4">
                                <input type="radio" name="costRange" checked={newPriceSetting.costRange} className="mr-2" onChange={() => setNewPriceSetting({ ...newPriceSetting, costRange: true })} />
                                Apply to products with a cost between <span className="mx-4"><span className="mr-1">$</span><input type="number" value={newPriceSetting.minCost} onChange={e => setNewPriceSetting({ ...newPriceSetting, minCost: e.target.value })} className={!newPriceSetting.costRange ? 'form-input--disabled' : ''} disabled={!newPriceSetting.costRange ? true : false} /></span>
                                And <span className="mx-4"><span className="mr-1">$</span><input type="number" value={newPriceSetting.maxCost} onChange={e => setNewPriceSetting({ ...newPriceSetting, maxCost: e.target.value })} className={!newPriceSetting.costRange ? 'form-input--disabled' : ''} disabled={!newPriceSetting.costRange ? true : false} /></span>
                            </label>
                            <h6 className="text-blue-link mb-4">Select a Method</h6>
                            <label className="mb-4">
                                <input type="radio" name="adjustmentMethod" checked={newPriceSetting.adjustmentMethod === 'percent'} onChange={() => setNewPriceSetting({ ...newPriceSetting, adjustmentMethod: 'percent', priceAdjustmentDollars: 0 })} className="mr-2" />
                                Mark up by <span className="mx-4"><input type="number" name="priceAdjustmentPercent" value={newPriceSetting.priceAdjustmentPercent} onChange={e => setNewPriceSetting({ ...newPriceSetting, priceAdjustmentPercent: e.target.value })} className={newPriceSetting.adjustmentMethod !== 'percent' ? 'form-input--disabled' : ''} disabled={newPriceSetting.adjustmentMethod !== 'percent' ? true : false} /><span className="ml-1">%</span></span> with a minimum of <span className="mx-4"><span className="mr-1">$</span><input type="number" name="priceAdjustmentPercentMin" value={newPriceSetting.priceAdjustmentPercentMin} onChange={e => setNewPriceSetting({ ...newPriceSetting, priceAdjustmentPercentMin: e.target.value })} className={newPriceSetting.adjustmentMethod !== 'percent' ? 'form-input--disabled' : ''} disabled={newPriceSetting.adjustmentMethod !== 'percent' ? true : false} /></span>
                            </label>
                            <label className="mb-4">
                                <input type="radio" name="adjustmentMethod" checked={newPriceSetting.adjustmentMethod === 'dollars'} onChange={() => setNewPriceSetting({ ...newPriceSetting, adjustmentMethod: 'dollars', priceAdjustmentPercent: 0, priceAdjustmentPercentMin: 0 })} className="mr-2" />
                                Set a mark-up of <span className="mx-4"><span className="mr-1">$</span><input type="number" name="priceAdjustmentDollars" value={newPriceSetting.priceAdjustmentDollars} onChange={e => setNewPriceSetting({ ...newPriceSetting, priceAdjustmentDollars: e.target.value })} className={newPriceSetting.adjustmentMethod !== 'dollars' ? 'form-input--disabled' : ''} disabled={newPriceSetting.adjustmentMethod !== 'dollars' ? true : false} /></span>
                            </label>
                            <button className="btn block ml-auto" type="submit">Save {saving ? <FontAwesomeIcon icon={faSpinner} className="spinner ml-2" /> : ''}</button>
                        </form>
                    </div>
                    : ''
                }
            </div>
        </div>
    );
}
