import React, { useState, useEffect, useCallback } from 'react';
import InsideHeader from '../components/InsideHeader';
import axios from 'axios';
import { useToast } from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';

export default function AddCompany() {
    const toast = useToast();
    const [companies, setCompanies] = useState([]);
    const [company, setCompany] = useState([]);
    const [newCompany, setNewCompany] = useState({
        company: '',
        companyId: '',
    })
    const [isSaving, setIsSaving] = useState(false);
    const { user } = useAuth0();

    const getCompanies = useCallback(async () => {
        setCompanies([]);
        setCompany("");
        try {
            const { data } = await axios.get(`/api/v1/company`);

            setCompanies(data.data.companies)
            setCompany(data.data.userCompany)
        } catch (err) {
            console.error(err.message);
        }
    }, [])

    const createCompany = async () => {
        try {
            setIsSaving(true)
            await axios.post(`/api/v1/company`, {
                CompanyId: newCompany.companyId,
                Company: newCompany.company
            }, {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8'
                }
            });
            getCompanies();
            setIsSaving(false)
            toast({
                title: `New Company Added`,
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
        } catch (err) {
            console.error(err.message);
            setIsSaving(false)
            toast({
                title: `Error`,
                description: `${err.response.data.errorMessages}`,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }

    const onSelectCompany = async (e) => {
        //setStoreSettings({ ...storeSettings, warehouseId: e.target.value });
        try {
            setIsSaving(true);
            await axios.put(`/api/v1/user/company/${e.target.value}`, {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8'
                }
            });
            getCompanies();
            setIsSaving(false);
            toast({
                title: `User company updated`,
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
        } catch (err) {
            console.error(err.message);
            setIsSaving(false);
            toast({
                title: `Error`,
                description: `${err.response.data.errorMessages}`,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }

    useEffect(() => {
        getCompanies();
    }, [getCompanies]);


    return (
        <>
            <InsideHeader heading="Add a Company" imageSrc="/hero-tire_rec.png" imageSrcSet="/hero-tire_rec.png, /hero-tire_rec@2x.png 2x" imageAlt="Tires on racks" />
            {user?.custom_roles.includes("Administrator") && <div className="px-6 py-10">
                {/*add new company*/}
                <h2 className="text-blue text-2xl mb-4">New Company Info</h2>
                <div className="flex items-center w-full">
                    <label className="mb-2 mr-2 flex flex-col flex-1 text-sm font-sans-reg font-bold text-gray-dark">COMPANY NAME:
                        <input type="text" className="form-input border border-gray-300 shadow-inner" value={newCompany.company} onChange={e => setNewCompany({ ...newCompany, company: e.target.value })} />
                    </label>
                    <label className="mb-2 mr-2 flex flex-col flex-1 text-sm font-sans-reg font-bold text-gray-dark">COMPANY NUMBER:
                        <input type="text" className="form-input border border-gray-300 shadow-inner" value={newCompany.companyId} onChange={e => setNewCompany({ ...newCompany, companyId: e.target.value })} />
                    </label>
                    <button className="btn translate-y-2" onClick={() => createCompany()}>{isSaving ? 'Saving...' : 'CREATE'}</button>
                </div>
                <div className="border border-1 my-6"></div>
                <h2 className="text-2xl text-blue font-reg mb-4">Update the company tied to your account</h2>
                <select value={company ?? ""} onChange={onSelectCompany}>
                    <option value="">Select One</option>
                    {Object.keys(companies).map((key, index) => (
                        <option value={key} key={index}>{companies[key]}</option>
                    ))}
                </select>
            </div>}    
        </>
    );
}
