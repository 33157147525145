import React, { useRef, useState, useEffect, useCallback } from 'react';
import InsideHeader from '../components/InsideHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSpinner } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { useToast } from '@chakra-ui/react'
import { useAuth0 } from '@auth0/auth0-react';

export default function FileUploads() {
    const [uploading, setUploading] = useState(false);
    const [uploadingFields, setUploadingFields] = useState(false);
    const [files, setFiles] = useState([]);
    const [filesWD, setFilesWD] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [fileType, setFileType] = useState('File Type');
    const [noFileType, setNoFileType] = useState();
    const [noFieldsSelected, setNoFieldsSelected] = useState();
    const fileInput = useRef();
    const toast = useToast();
    const { user } = useAuth0();
    const [uploadData, setUploadData] = useState();
    const [headerRowIndex, setHeaderRowIndex] = useState();
    const [headerMap, setHeaderMap] = useState({});

    const [noStores, setNoStores] = useState();
    const [stores, setStores] = useState([]);
    const [isWDUpload, setIsWDUpload] = useState(false);

    const onReset = () => {
        setUploading(false);
        setFiles([]);
        setUploadData();
        setFileType('File Type');
        setIsWDUpload(false);
        setHeaderMap([]);
        fileInput.current.value = '';
    }

    const onFileType = e => {
        onReset();
        setFileType(e.target.value);
        setNoFileType(false);
        if (e.target.value === 'WD Primary' || e.target.value === 'WD Secondary') {
            setIsWDUpload(true);
        } else {
            setIsWDUpload(false);
        }
    }

    const onCheck = e => {
        setStores(stores.map(store => {
            if (store.dealerId === e.target.value) {
                store.checked = !store.checked;
            }
            return store;
        }))
    }
    const onSelectAll = e => {
        if (e.target.checked) {
            setStores(stores.map(store => {
                store.checked = true
                return store
            }))
        } else {
            setStores(stores.map(store => {
                store.checked = false
                return store
            }))
        }
    }

    const validate = () => {
        if (fileType === 'File Type') {
            setNoFileType(true);
            return false;
        } else {
            setNoFileType(false);
        }
        if (isWDUpload) {
            const storeSelected = store => store.checked === true;
            if (stores.some(storeSelected)) {
                setNoStores(false);
            } else {
                setNoStores(true);
                return false;
            }
        }
        return true;
    }
    const validateHeaderFields = () => {
      if (!Object.keys(headerMap).length) {
        setNoFieldsSelected(true);
        return false;
      } else {
        for (const field of uploadData.fields) {
          if (!headerMap[field]) {
            setNoFieldsSelected(true);
            return false;
          }
        }
      }
      setNoFieldsSelected(false);
      return true;
    }

    const getFiles = useCallback(async () => {
        try {
            const { data: companyData } = await axios.get(`/api/v1/import/all`);
            setFiles(companyData.data);
        } catch (err) {
            console.error(err);
        }
    }, [])

    // create select input refs
    const selectInputRefs = useRef({});

    const onSelectHeaderRow = e => {
        // reset the select inputs when the header row changes
        Object.values(selectInputRefs.current).forEach((ref => {
           ref.selectedIndex = 0;
        }));

        setHeaderMap({});
        setHeaderRowIndex(parseInt(e.target.value));
    }

    const onSelectHeaderMap = (field, val) => {
        setHeaderMap({ ...headerMap, [field]: val});
    }

    const onSubmit = async e => {
        e.preventDefault();
        if (validate()) {
            try {
                setUploading(true);
                let formData = new FormData();
                formData.append('type', fileType);
                formData.append('', fileInput.current.files[0], fileInput.current.files[0].name);
                // get header rows
                let { data: fileData } = await axios.post('/api/v1/import/get-top-rows', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                });
                setUploadData(fileData.data);
                return;
            } catch (err) {
                toast({
                    title: `Error`,
                    description: `${err.response.data.errorMessages}`,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
                console.error(err);
                setUploading(false);
            }
        }
    }
    // submit the file with the selected header row index and field mapping
    const onSubmitFields = async e => {
        e.preventDefault();        
        if (validate() && validateHeaderFields()) {
            try {
                setUploadingFields(true);
                let formData = new FormData();
                formData.append('type', fileType);
                formData.append('', fileInput.current.files[0], fileInput.current.files[0].name);

                // append type
                formData.append('headerRowIndex', headerRowIndex + 1); // This is the row number in the file where the headers are located starting with 1 (not 0).
                formData.append('headerMap', JSON.stringify(headerMap));

                // append dealer IDs if WD upload
                if (isWDUpload) {
                    let dealerIds = '';
                    for (let store of stores) {
                        if (store.checked) {
                            dealerIds += store.dealerId + ',';
                        }
                    }
                    formData.append('ids', dealerIds);
                }
                await axios.post('/api/v1/import', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                });
                toast({
                    title: `Success`,
                    description: `${fileInput.current.files[0].name} uploaded`,
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                });
                setUploading(false);
                setUploadingFields(false);
                setFileType('File Type');
                onReset();
                getFiles();
            } catch (err) {
                toast({
                    title: `Error`,
                    description: `${err.response.data.errorMessages}`,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
                console.error(err);
                setUploading(false);
                setUploadingFields(false);
            }
        }
    }

    const getFilesWD = useCallback(async () => {
        let dealerIds = '';
        for (let store of stores) {
            dealerIds += store.dealerId + ',';
        }
        try {
            const { data: dealerData } = await axios.get(`/api/v1/import/wd?ids=${dealerIds}`);
            setFilesWD(Object.entries(dealerData.data));
        } catch (err) {
            console.error(err);
        }
    }, [stores])

    const getCompanyDealers = useCallback(async () => {
        try {
            const { data } = await axios.get(`/api/v1/store/get`);
            setStores(data.data)
        } catch (err) {
            console.error(err.message);
        }
    }, [])

    useEffect(() => {
        getCompanyDealers();
        getFiles();
    }, [getCompanyDealers, getFiles]);

    useEffect(() => {
        getFilesWD();
    }, [getFilesWD, stores])

    return (
        <div className="relative h-[100vh]">
            <InsideHeader heading="File Uploads" imageSrc="/hero-tire_rec.png" imageSrcSet="/hero-tire_rec.png, /hero-tire_rec@2x.png 2x" imageAlt="Tires on racks" />
            <div className="px-6 py-10">
                <p className="mb-2 text-lg">Upload pricing spreadsheet for a WD below. You can also view current pricing database in use.</p>
                <button className="btn block ml-auto" onClick={() => setModalOpen(!modalOpen)}>Upload</button>
                {files.length === 0 && filesWD.length === 0
                    ?
                    <h3>No pricing files have been added.</h3>
                    :
                    <table className="w-full table-auto text-sm font-sans-reg text-left border-separate border-spacing-y-2.5">
                        <thead className="text-gray-dark">
                            <tr>
                                <th>TYPE</th>
                                <th>NAME</th>
                                <th>DATE UPLOADED</th>
                                <th>STATUS</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {files.map((file, index) =>
                                <tr className="even:bg-white odd:bg-blue-pale" key={index}>
                                    <td className="px-5 py-4">{file.type}</td>
                                    <td className="px-5 py-4">{file.name}</td>
                                    <td className="px-5 py-4">{file.date}</td>
                                    <td className="px-2 py-4">{file.status}</td>
                                    <td className="px-2 py-4">
                                        {file.errors.length > 0 &&
                                            <details>
                                                <summary className="text-red-danger">Errors</summary>
                                                <pre>
                                                    {JSON.parse(file.errors).map(error => {
                                                        return error + '\n'
                                                    })}
                                                </pre>
                                            </details>
                                        }
                                    </td>
                                </tr>
                            )}
                            {filesWD.map(dealer =>
                                dealer[1].map((file, index) =>
                                    <tr className="even:bg-white odd:bg-blue-pale" key={index}>
                                        <td className="px-5 py-4">{file.type} - {dealer[0]}</td>
                                        <td className="px-5 py-4">{file.name}</td>
                                        <td className="px-5 py-4">{file.date}</td>
                                        <td className="px-2 py-4">{file.status}</td>
                                        <td className="px-2 py-4">
                                            {file.errors.length > 0 &&
                                                <details>
                                                    <summary className="text-red-danger">Errors</summary>
                                                    <pre>
                                                        {JSON.parse(file.errors).map(error => {
                                                            return error + '\n'
                                                        })}
                                                    </pre>
                                                </details>
                                            }
                                        </td>
                                    </tr>
                                )
                            )}
                        </tbody>
                    </table>
                }
                {modalOpen ?
                    <div className="absolute z-10 top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] w-[97%] h-[97%] bg-white p-6 rounded shadow-lg overflow-y-auto">
                        <div className="flex justify-between items-center mb-12">
                            <h1 className="text-gray-dark text-[36px]">Upload New File</h1>
                            <button className="btn-blue" onClick={() => setModalOpen(!modalOpen)}><FontAwesomeIcon icon={faTimes} /></button>
                        </div>
                        <form className="flex flex-col" onSubmit={onSubmit}>
                            <label className="text-2xl mb-7">
                                Select file type:
                                <select className="form-select border-1 border-blue-link text-blue-link shadow-inner rounded ml-6" name="fileType" value={fileType} onChange={onFileType}>
                                    <option disabled value="File Type">File Type</option>
                                    {user?.custom_roles.includes("Owner") && <option className="text-blue-link" value="QOH">Inventory</option>}
                                    {user?.custom_roles.includes("Owner") && <option className="text-blue-link" value="MAP">MAP</option>}
                                    {user?.custom_roles.includes("Owner") && <option className="text-blue-link" value="PMP">Market Price</option>}
                                    <option className="text-blue-link" value="WD Primary">WD Primary</option>
                                    <option className="text-blue-link" value="WD Secondary">WD Secondary</option>
                                </select>
                                {noFileType
                                    ? <span className="text-lg text-red-danger font-bold ml-4">Please select a file type</span>
                                    : ''
                                }
                            </label>
                            <div className="flex flex-col">
                                <p className="mb-2">File must have a <strong>.csv</strong> or <strong>.xlsx</strong> file extension</p>
                                <input type="file" accept="text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" name="fileUpload" ref={fileInput} className="text-xl mb-7" required />
                            </div>
                            <button className="btn block ml-auto" type="submit">Upload {uploading ? <FontAwesomeIcon icon={faSpinner} className="spinner ml-2" /> : ''}</button>
                            {uploading && <button className="text-red-danger ml-auto mt-2" onClick={onReset}>Cancel Upload</button>}
                            {isWDUpload &&
                                <>
                                    <h3 className="font-sans-reg mb-7">SELECT STORES TO APPLY TO: {noStores ? <span className="text-lg text-red-danger font-sans font-bold ml-4">Please select at least 1 store location</span> : ''}</h3>
                                    <ul className="max-h-[225px] overflow-y-scroll mb-7 pb-7 border-b-2">
                                        {stores.map((store, index) =>
                                            <li className="px-5 py-4 odd:bg-blue-pale" key={index}>
                                                <label className="flex items-center">
                                                    <input type="checkbox" name="storeLocations" value={store.dealerId} checked={store.checked} onChange={onCheck} className="mr-4" />
                                                    {store.dealer}
                                                </label>
                                            </li>
                                        )}

                                    </ul>
                                    <label className="flex items-center pl-5">
                                        <input type="checkbox" name="selectAll" onChange={onSelectAll} className="mr-4" />
                                        Select All
                                    </label>
                                </>
                            }
                            {uploadData &&
                                <>
                                <h4 className="text-xl">Document Rows:</h4>
                                <p>Select the header row</p>
                                <div className="overflow-x-auto mb-8">
                                    <table className="w-full table-auto text-sm font-sans-reg text-left border-separate border-spacing-y-2.5">
                                        <tbody>
                                            {uploadData.rows.map((row, key) =>
                                                <tr className="even:bg-white odd:bg-blue-pale" key={key}>
                                                    <td className="px-5 py-4"><input type="radio" name="header_row_index" value={key} onChange={onSelectHeaderRow} /></td>
                                                    {Object.keys(row).map((field, key) =>
                                                        <td className="px-5 py-4" key={key}>{field}: {row[field]}</td>
                                                    )}
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>   
                                </div>
                                {headerRowIndex !== undefined &&
                                    <>
                                        <h4 className="text-xl">Required Fields:</h4>
                                        <p>Select the corresponding fields to use from the document</p>
                                        {noFieldsSelected
                                            ? <span className="text-lg text-red-danger font-bold">Please select the fields to map</span>
                                            : ''
                                        }
                                        <table className="w-full table-auto text-sm font-sans-reg text-left border-separate border-spacing-y-2.5">
                                                <tbody>
                                                    {uploadData.fields.map((field, key) =>
                                                        <tr className="even:bg-white odd:bg-blue-pale" key={key}>
                                                            <td className="px-5 py-4">{field}:
                                                                <select defaultValue={'Select a field'} className="ml-4" ref={ref => (selectInputRefs.current[key] = ref)} onChange={e => onSelectHeaderMap(field, e.target.value)} required>
                                                                    <option disabled>Select a field</option>
                                                                    {Object.keys(uploadData.rows[headerRowIndex]).map((row, key) => {
                                                                        if (uploadData.rows[headerRowIndex][row] !== "") {
                                                                            return <option key={key}>{uploadData.rows[headerRowIndex][row]}</option>
                                                                        } else {
                                                                            return null;
                                                                        }
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                        </table>
                                    <button className="btn block ml-auto" onClick={onSubmitFields}>Continue {uploadingFields ? <FontAwesomeIcon icon={faSpinner} className="spinner ml-2" /> : ''}</button>
                                    </>
                                }
                                </>
                            }
                        </form>
                    </div>
                    : ''
                }
            </div>
        </div >
    );
}
