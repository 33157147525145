import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { GetApiUrl, GetAuthClientId, GetAuthDomain, GetRollbarConfig } from './helpers/Utils'
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import './index.css';
import Root from './routes/Root';
import ErrorPage from './routes/ErrorPage';
import reportWebVitals from './reportWebVitals';
import { AuthenticationGuard } from "./helpers/AuthenticationGuard";
import Callback from './routes/Callback';
import TireRecommendations from './routes/TireRecommendations';
import Login from './routes/Login';
import GrossProfitMarkUp from './routes/GrossProfitMarkUp';
import PriceSettings from './routes/PriceSettings';
import FileUploads from './routes/FileUploads';
import SetPermissions from './routes/SetPermissions';
import StoreSettings from './routes/StoreSettings';
import Users from './routes/Users';
import ManageCompanies from './routes/ManageCompanies';
import axios from 'axios';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './chakra-theme';
import { Provider, ErrorBoundary } from '@rollbar/react';

if (process.env.NODE_ENV !== 'development') {
    axios.defaults.baseURL = GetApiUrl();
}

// redirect non-www to www
if (window.location.hostname === 'tireshoppro.com') {
    window.location.replace(`https://www.tireshoppro.com${window.location.pathname}`);
}

const router = createBrowserRouter([
    {
        element: <Root />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "tire-recommendations",
                element: <AuthenticationGuard component={ TireRecommendations } />
            },
            {
                path: "price-settings",
                element: <AuthenticationGuard component={ PriceSettings } />
            },
            {
                path: "gross-profit-mark-up",
                element: <AuthenticationGuard component={ GrossProfitMarkUp } />
            },
            {
                path: "file-uploads",
                element: <AuthenticationGuard component={ FileUploads } />
            },
            {
                path: "store-settings",
                element: <AuthenticationGuard component={ StoreSettings } />
            },
            {
                path: "users",
                element: <AuthenticationGuard component={Users} />
            },
            {
                path: "manage-companies",
                element: <AuthenticationGuard component={ManageCompanies} />
            }
        ]
    },
    {
        path: "/",
        element: <Login />,
        errorElement: <ErrorPage />
    },
    {
        path: "callback",
        element: <Callback />,
        errorElement: <ErrorPage />
    },
    {
        path: "set-permissions",
        element: <AuthenticationGuard component={SetPermissions} />
    }
])

const onRedirectCallback = async (appState, user) => {
    if (user?.custom_roles?.includes("Owner") || user?.custom_roles?.includes("Manager") || user?.custom_roles?.includes("Associate")) {
        window.location.replace(
            appState && appState.returnTo
                ? appState.returnTo
                : '/tire-recommendations'
        );
    }
    else {
        window.location.replace('set-permissions');
    }
};

const setAxiosTokenInterceptor = async (getAccessTokenSilently) => {
    axios.interceptors.request.use(async config => {
        let token = '';
        if (config.url !== '/api/v1/user/update-token') {
            token = await getAccessTokenSilently();
        } else {
            token = await getAccessTokenSilently({ cacheMode: 'off' });
        }
        const requestConfig = config
        if (token) {
            requestConfig.headers.Authorization = `Bearer ${token}`
        }
        return requestConfig
    })
}

const App = () => {
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        setAxiosTokenInterceptor(getAccessTokenSilently);
    }, [getAccessTokenSilently]);

    return <ChakraProvider theme={theme} resetCSS={false}>
                <RouterProvider router={router} />
            </ChakraProvider>
}

const rollbarConfig = GetRollbarConfig();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<React.StrictMode>
    {/* Provider instantiates Rollbar client instance handling any uncaught errors or unhandled promises in the browser */}
    <Provider config={rollbarConfig}>
        {/* ErrorBoundary catches all React errors in the tree below and logs them to Rollbar */}
        <ErrorBoundary>
            <Auth0Provider
                domain={GetAuthDomain()}
                clientId={GetAuthClientId()}
                onRedirectCallback={onRedirectCallback}
                cacheLocation="localstorage"
                authorizationParams={{
                    redirect_uri: `${window.location.origin}/callback`,
                    audience: `${GetApiUrl()}/api/`
                }}
            >
                <App />
            </Auth0Provider>
        </ErrorBoundary>
    </Provider>
</React.StrictMode>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
