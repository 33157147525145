import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

export default function UserAccordion(props) {
    const dealerId = props.storeNumber;
    const toast = useToast();
    const [showContent, setShowContent] = useState(false);
    const [contentLoaded, setContentLoaded] = useState(false);
    const [users, setUsers] = useState({});
    const { user } = useAuth0();
    const [newUser, setNewUser] = useState({
        email: ''
    })

    const updateNewUserEmail = val => {
        setNewUser({
            ...newUser,
            email: val
        })
    }
    const updateNewUserType = val => {
        setNewUser({
            ...newUser,
            type: val
        })
    }
    const addUser = () => {
        if (newUser.email !== '' && newUser.type !== '') {
            createUser();
            setNewUser({
                email: '',
                type: ''
            })
        }
    }

    const removeUser = async email => {
        try {
            await axios.delete(`/api/v1/user/dealer/${dealerId}`, {
                data: {
                    email: email
                }
            });
            getUsers();
            toast({
                title: `User Deleted`,
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
        } catch (err) {
            console.error(err)
            toast({
                title: `Error`,
                description: `${err.response.data.errorMessages}`,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }

    const getUsers = useCallback(async () => {
        try {
            const { data } = await axios.get(`/api/v1/user/dealer/${dealerId}`);
            setUsers(data.data);
            setContentLoaded(true);
            //console.log(data.data);
        } catch (err) {
            console.error(err);
            toast({
                title: `Error`,
                description: `${err.response.data.errorMessages}`,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }, [toast, dealerId])

    const createUser = useCallback(async () => {
        try {
            await axios.post(`/api/v1/user/dealer/${dealerId}`,
                {
                    email: newUser.email,
                    type: newUser.type
                },
                {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8'
                    },
                }
            )
            getUsers();
            //console.log(data)
            toast({
                title: `New User Created`,
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
        } catch (err) {
            console.error(err);
            toast({
                title: `Error`,
                description: `${err.response.data.errorMessages}`,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }, [getUsers, newUser.email, newUser.type, toast, dealerId])

    useEffect(() => {
        getUsers();
    }, [getUsers])

    return (
        contentLoaded &&
        <div className="mb-2 group">
            <div className={`flex items-center w-full pl-6 pr-2 py-2 rounded ${showContent ? 'bg-blue text-white' : 'group-even:bg-blue-pale'}`}>
                <span className="w-1/3 text-xl">{props.storeName}</span>
                <span className="w-1/3 text-xl">{props.storeNumber}</span>
                <button className="bg-blue flex items-center justify-center text-white text-2xl ml-auto px-4 p-1 w-[42px] h-[42px] rounded" onClick={() => setShowContent(!showContent)}><FontAwesomeIcon icon={!showContent ? faCaretDown : faCaretUp} /></button>
            </div>
            {showContent &&
                <div className="bg-white p-6">
                    {/*store users*/}
                    <h2 className="text-2xl text-blue font-reg mb-4">Store Users</h2>
                    <table className="w-full table-fixed text-sm font-sans-reg text-left border-separate border-spacing-y-2.5">
                        <thead className="text-gray-dark">
                            <tr>
                                <th>EMAIL</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.length === 0 &&
                                <tr><td>There are no users added</td><td></td></tr>
                            }
                                {users.map(duser =>
                                    <tr className="even:bg-white odd:bg-blue-pale" key={duser.userId}>
                                        <td className="p-2">{duser.email}</td>
                                    <td className="p-2 text-right">
                                        {user.email !== duser.email && <button className="btn-blue" onClick={() => removeUser(duser.email)}><FontAwesomeIcon icon={faTimes} /></button>}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    {/*add store user*/}
                    <h2 className="text-blue text-2xl mb-4">Add a New User</h2>
                    <div className="flex items-center w-full">
                        <label className="mb-2 mr-2 flex flex-col flex-1 text-sm font-sans-reg font-bold text-gray-dark">USER EMAIL:
                            <input type="email" className="form-input border border-gray-300 shadow-inner" value={newUser.email} onChange={e => updateNewUserEmail(e.target.value)} />
                        </label>
                        <label className="mb-2 mr-2 flex flex-col flex-1 text-sm font-sans-reg font-bold text-gray-dark">TYPE</label>
                        <select id="type" onChange={e => updateNewUserType(e.target.value)} value={newUser.type}>
                            <option value="">Select Type</option>
                            <option value="Manager">Manager</option>
                            <option value="Associate">Associate</option>
                        </select>
                        <button className="btn translate-y-2" onClick={() => addUser()}>CREATE</button>
                    </div>
                </div>
            }
        </div>
    );
}
