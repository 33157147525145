import React from 'react';


export default function TireCard(props) {
    const currency = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
    // store fees
    const totalNonTaxableFees = () => {
        let sum = 0;
        props.fees.required.forEach(fee => {
            if (!fee.taxable) {
                sum += fee.amount;
            }
        })
        return sum;
    }
    const totalTaxableFees = () => {
        let sum = 0;
        props.fees.required.forEach(fee => {
            if (fee.taxable) {
                sum += fee.amount;
            }
        })
        return sum;
    }
    // optional fees (per order)
    const totalOptionalNonTaxableFees = () => {
        let sum = 0;
        props.resultsSettings.showOptionalFees.forEach(feeId => {
            props.fees.optional.forEach(fee => {
                if (fee.feeId === feeId && !fee.taxable && fee.perTire !== true) {
                    // optional fees can be a percentage so we account for that here
                    sum += fee.amount + ((props.retail * fee.percent) / 100);
                }
            })
        })
        return sum;
    }
    const totalOptionalTaxableFees = () => {
        let sum = 0;
        props.resultsSettings.showOptionalFees.forEach(feeId => {
            props.fees.optional.forEach(fee => {
                if (fee.feeId === feeId && fee.taxable && fee.perTire !== true) {
                    // optional fees can be a percentage so we account for that here
                    sum += fee.amount + ((props.retail * fee.percent) / 100);
                }
            })
        })
        return sum;
    }
    // optional fees (per tire)
    const totalOptionalNonTaxableFeesTire = () => {
        let sum = 0;
        props.resultsSettings.showOptionalFees.forEach(feeId => {
            props.fees.optional.forEach(fee => {
                if (fee.feeId === feeId && !fee.taxable && fee.perTire === true) {
                    // optional fees can be a percentage so we account for that here
                    sum += fee.amount + ((props.retail * fee.percent) / 100);
                }
            })
        })
        return sum;
    }
    const totalOptionalTaxableFeesTire = () => {
        let sum = 0;
        props.resultsSettings.showOptionalFees.forEach(feeId => {
            props.fees.optional.forEach(fee => {
                if (fee.feeId === feeId && fee.taxable && fee.perTire === true) {
                    // optional fees can be a percentage so we account for that here
                    sum += fee.amount + ((props.retail * fee.percent) / 100);
                }
            })
        })
        return sum;
    }

    return (
        <div className={`p-4 ${props.highlight && props.storeView ? "bg-yellow-lighter border-yellow" : "border-gray"} ${!props.storeView ? 'mt-[2.5rem] mb-[5rem] ml-6' : ''} border flex rounded`}>

            <img src={props.tireImage} srcSet={props.tireImage ? props.tireImage : "./tire-sample/tire-sample.png, ./tire-sample/tire-sample@2x.png 2x"} alt="tire name" className={`${!props.storeView ? 'mt-[-2rem] mb-[-4rem] translate-x-[-20%]' : 'translate-x-[-30%]'} object-contain`} loading="lazy" width={`${props.storeView ? '92' : '190'}`} />
            <div className={`flex flex-wrap ${!props.storeView ? 'items-start' : ''} w-full`}>
                <div className="flex w-full space-between items-center relative">
                    {props.rank !== null && props.rank !== 0
                        ? <img src={`/badge-${props.rank}.svg`} alt="rank" className="absolute left-[-3rem]" />
                        : <></>
                    }
                    <div className={`flex flex-col flex-grow relative`}>
                        <span className="text-blue text-[18px] uppercase">{props.tireBrand}</span>
                        <span className={`text-blue font-bold ${!props.storeView ? 'text-xl' : 'text-[24px]'}`}>{props.tireName}</span>
                    </div>
                    {/*<button className="text-blue-lighter underline text-xs ml-auto">Tire details</button>*/}
                    {/*<button className="text-blue-lighter underline text-xs ml-4">Print</button>*/}
                    {props.storeView &&
                        <>
                            {props.quantityAvailable === 0 && props.quantityOnHand === 0 &&
                                <span className="bg-red-danger text-right text-white font-bold text-[16] leading-[14px] px-2 py-1 ml-2">OUT OF STOCK</span>
                            }
                            {props.quantityAvailable > 0 &&
                                <span className="bg-green-success text-right text-white font-bold text-[16] leading-[14px] px-2 py-1 ml-2">IN STOCK: {props.quantityAvailable} Available</span>
                            }
                            {props.quantityOnHand > 0 &&
                                <span className="bg-green-success text-right text-white font-bold text-[16] leading-[14px] px-2 py-1 ml-2">ON HAND: {props.quantityOnHand} Available</span>
                            }
                            {props.quantityAvailable === null &&  props.quantityOnHand === 0 &&
                                <span className="bg-yellow-warning text-right text-black font-bold text-[16] leading-[14px] px-2 py-1 ml-2">STATUS UNAVAILABLE</span>
                            }
                        </>
                    }

                </div>
                {/* Features */}
                <div className={`flex flex-col items-start ${props.storeView ? 'border-r ' : ''} pr-4 w-[40%]`}>
                    <div className={`${props.storeView ? 'text-blue' : 'text-gray-dark'} text-[16px]`}>
                        <span className={`${!props.storeView ? 'hidden' : ''}`}>{props.tireSize} <span className="text-gray px-2">|</span></span>
                        <span className={`${!props.storeView ? 'hidden' : ''}`}>{props.tireNumber} <span className="text-gray px-2">|</span></span>
                        <span className="uppercase">{props.tireType}</span>
                    </div>
                    <div className="flex w-full">
                        <ul className="flex flex-col flex-grow">
                            {props.storeView === false &&
                                <li className="flex text-[16]">
                                    <span className="w-1/2 mr-2 text-gray-dark">Tire Size:</span>
                                    <span className="w-1/2">{props.tireSize}</span>
                                </li>
                            }
                            <li className="flex text-[16]">
                                <span className="w-1/2 mr-2 text-gray-dark">UTQG:</span>
                                <span className="w-1/2">{props.UTQG}</span>
                            </li>
                            <li className="flex text-[16]">
                                <span className="w-1/2 mr-2 text-gray-dark">Load Index:</span>
                                <span className="w-1/2">{props.loadIndex}</span>
                            </li>
                            <li className="flex text-[16]">
                                <span className="w-1/2 mr-2 text-gray-dark">Load Range:</span>
                                <span className="w-1/2">{props.loadRange}</span>
                            </li>
                            <li className="flex text-[16]">
                                <span className="w-1/2 mr-2 text-gray-dark">Sidewall:</span>
                                <span className="w-1/2">{props.sidewall}</span>
                            </li>
                            <li className="flex text-[16]">
                                <span className="w-1/2 mr-2 text-gray-dark">Speed Rating:</span>
                                <span className="w-1/2">{props.speedRating}</span>
                            </li>
                            <li className="flex text-[16]">
                                <span className="w-1/2 mr-2 text-gray-dark">Warranty:</span>
                                <span className="w-1/2">{props.warranty}</span>
                            </li>
                            {props.storeView === false &&
                                <li className="flex text-sm">
                                    <span className="w-1/2 mr-2 text-gray-dark">Price of 1 Tire:</span>
                                    <span className="w-1/2">{currency.format(props.retail)}</span>
                                </li>
                            }
                        </ul>
                        <ul className="flex flex-col items-end">
                            {props.runOnFlat
                                ? <li className="bg-blue text-white mb-2 py-[2px] px-[4px] rounded">Run on Flat</li>
                                : ''
                            }
                            {props.foam
                                ? <li className="bg-blue text-white mb-2 py-[2px] px-[4px] rounded">Noise Technology</li>
                                : ''
                            }
                            {props.studdable
                                ? <li className="bg-blue text-white mb-2 py-[2px] px-[4px] rounded">Studdable</li>
                                : ''
                            }
                            {props.oem
                                ? <li className="bg-blue text-white mb-2 py-[2px] px-[4px] rounded">OEM: {props.oem}</li>
                                : ''
                            }
                        </ul>
                    </div>
                </div>
                {/* Pricing */}
                {props.storeView === true &&
                    <table className="w-[60%] pl-4 table-fixed">
                        <thead className="text-gray-darker">
                            <tr>
                                <th className="text-[18px] font-normal w-1/5 text-center">Quantity:</th>
                                {props.showCost &&
                                    <th className="text-[18px] font-normal text-center w-1/5">Dealer Cost:</th>
                                }
                                {props.salesView === 'show-additional-options' && props.mapPrice !== null &&
                                    <th className="text-[18px] font-normal text-center w-1/5">MAP Price:</th>
                                }
                                {props.salesView === 'show-additional-options' && props.pmpPrice !== null &&
                                    <th className="text-[18px] font-normal text-center w-1/5">Market Price:</th>
                                }
                                <th className="text-[18px] font-normal text-center w-1/5">Retail:</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><img src="/icon-tire.svg" alt="single tire" className="mx-auto" /></td>
                                {props.showCost &&
                                    <td className="font-bold text-[16px] text-center">{currency.format(props.dealerCost)}</td>
                                }
                                {props.salesView === 'show-additional-options' && props.mapPrice !== null &&
                                    <td className="text-[16px] font-normal text-center w-1/5">{currency.format(props.mapPrice)}</td>
                                }
                                {props.salesView === 'show-additional-options' && props.pmpPrice !== null &&
                                    <td className="text-[16px] font-normal text-center w-1/5">{currency.format(props.pmpPrice)}</td>
                                }
                                <td className="font-bold text-center">{currency.format(props.retail)}</td>
                            </tr>
                            <tr>
                                <td><img src="/icon-4tires.svg" alt="four tires" className="mx-auto" /></td>
                                {props.showCost &&
                                    <td className="font-bold text-center">{currency.format(props.dealerCost * 4)}</td>
                                }
                                {props.salesView === 'show-additional-options' && props.mapPrice !== null &&
                                    <td className="text-[16px] font-normal text-center w-1/5">{currency.format(props.mapPrice * 4)}</td>
                                }
                                {props.salesView === 'show-additional-options' && props.pmpPrice !== null &&
                                    <td className="text-[16px] font-normal text-center w-1/5">{currency.format(props.pmpPrice * 4)}</td>
                                }
                                <td className="font-bold text-center">{currency.format(props.retail * 4)}</td>
                            </tr>
                        </tbody>
                    </table>
                }
                {props.storeView === false &&
                    <table className="w-[60%] pl-4 table-fixed" cellPadding="2">
                        <tbody>
                            <tr className="text-gray-darker">
                                <td className="text-[16px] font-normal w-1/5">Price of {props.resultsSettings.qty} Tire(s):</td>
                                <td className="text-[16px] font-normal w-1/5">Price of {props.resultsSettings.qty} Tire(s) w/ Tax:</td>
                            </tr>

                            <tr>
                                <td className="font-bold text-2xl text-blue">{currency.format(props.retail * props.resultsSettings.qty)}</td>
                                <td className="font-bold text-2xl text-blue">{currency.format((props.retail * props.resultsSettings.qty) + ((props.retail * props.resultsSettings.qty) * props.taxRate) / 100)}</td>
                            </tr>
                            {props.resultsSettings.showFees === true &&
                                <tr className="text-gray-darker">
                                    <td className="text-[16px] font-normal w-1/5">Fees:<br />
                                        <span className="font-bold text-2xl text-blue">{currency.format((totalNonTaxableFees() + totalTaxableFees()) * props.resultsSettings.qty)}</span>
                                    </td>
                                    <td className="text-[16px] font-normal w-1/5">Fees w/ Tax:<br />
                                        <span className="font-bold text-2xl text-blue">{currency.format(((totalNonTaxableFees() + totalTaxableFees()) * props.resultsSettings.qty) + ((totalTaxableFees() * props.resultsSettings.qty)) * props.taxRate / 100)}</span>
                                    </td>
                                </tr>
                            }
                            {/*loop over optional fees*/}
                            {props.resultsSettings.showOptionalFees.map((feeId, index) => {
                                let feeName = props.fees.optional.find(fee => fee.feeId === feeId).fee;
                                let feeAmount = props.fees.optional.find(item => item.feeId === feeId).amount ? props.fees.optional.find(item => item.feeId === feeId).amount : 0;
                                let feePercent = props.fees.optional.find(item => item.feeId === feeId).percent ? props.fees.optional.find(item => item.feeId === feeId).percent : 0;
                                let feeCost = (feeAmount) + ((props.retail * feePercent) / 100); // either amount or percent will be 0 so we can use both here to determine cost
                                let feeTaxable = props.fees.optional.find(item => item.feeId === feeId).taxable;
                                let feePerTire = props.fees.optional.find(item => item.feeId === feeId).perTire;
                                return (

                                    <tr className="text-gray-darker" key={index}>
                                        <td className="text-[16px] font-normal w-1/5">{feeName}:<br />
                                            <span className="font-bold text-2xl text-blue">{currency.format(feePerTire ? feeCost * props.resultsSettings.qty : feeCost)}</span>
                                        </td>
                                        <td className="text-[16px] font-normal w-1/5">{feeName} w/ Tax: <br />
                                            <span className="font-bold text-2xl text-blue">{currency.format((feePerTire ? feeCost * props.resultsSettings.qty : feeCost)
                                                + (feeTaxable ? ((feePerTire ? feeCost * props.resultsSettings.qty : feeCost) * props.taxRate / 100) : 0))}
                                            </span>
                                        </td>
                                    </tr>
                                )
                            })}
                            <tr className="text-gray-darker">
                                <td className="text-[16px] font-normal w-1/5">Total:</td>
                                <td className="text-[16px] font-normal w-1/5">Total w/ Tax:</td>
                            </tr>
                            <tr>
                                <td className="font-bold text-2xl text-blue">
                                    {currency.format(((props.retail + (props.resultsSettings.showFees ? (totalNonTaxableFees() + totalTaxableFees()) : 0)
                                        + totalOptionalNonTaxableFeesTire() + totalOptionalTaxableFeesTire()) * props.resultsSettings.qty)
                                        + (totalOptionalNonTaxableFees() + totalOptionalTaxableFees())
                                    )}
                                </td>
                                <td className="font-bold text-2xl text-blue">
                                    {currency.format((props.retail + (props.retail * (props.taxRate / 100))
                                        + (props.resultsSettings.showFees ? (totalNonTaxableFees() + (totalTaxableFees() + (totalTaxableFees() * (props.taxRate / 100)))) : 0)
                                        + (totalOptionalNonTaxableFeesTire() + (totalOptionalTaxableFeesTire() + (totalOptionalTaxableFeesTire() * (props.taxRate / 100))))) * props.resultsSettings.qty
                                        + (totalOptionalNonTaxableFees() + (totalOptionalTaxableFees() + (totalOptionalTaxableFees() * (props.taxRate / 100))))
                                    )}
                                </td>
                                {/*<td className="font-bold text-2xl text-blue">*/}
                                {/*    {currency.format(*/}
                                {/*        ((((props.retail + (props.retail * (props.taxRate / 100)))*/}
                                {/*            + (props.resultsSettings.showFees ? (totalNonTaxableFees() + (totalTaxableFees() + (totalNonTaxableFees() * (props.taxRate / 100)))) : 0)*/}
                                {/*            + (props.resultsSettings.showRoadHazard ? (roadHazardCost + (roadHazardTaxable ? (roadHazardCost * (props.taxRate / 100)) : 0)) : 0)) * props.resultsSettings.qty*/}
                                {/*            + (props.resultsSettings.showAlignment ? (alignmentCost + (alignmentTaxable ? (alignmentCost * (props.taxRate / 100)) : 0)) : 0)*/}
                                {/*        ))*/}
                                {/*    )}*/}
                                {/*</td>*/}
                            </tr>
                        </tbody>
                    </table>
                }
            </div>
        </div>
    );
}