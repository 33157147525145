import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCar } from '@fortawesome/free-solid-svg-icons';
import { useOutletContext } from "react-router-dom";
import TireCard from '../components/TireCard';
import InsideHeader from '../components/InsideHeader';
import axios from 'axios';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { debounce } from 'lodash';
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, useToast } from '@chakra-ui/react'
import ProgressiveFilters from '../components/ProgressiveFilters';

export default function TireRecommendations(props) {
    const toast = useToast();
    const [isLoading, setIsLoading] = useState(false);
    const [fees, setFees] = useState({
        optional: [],
        required: []
    });
    const [storeSettings, setStoreSettings] = useState();
    const [stores, setStores] = useState([]);
    const [selectedStore, setSelectedStore] = useState("");
    const [storeView] = useOutletContext();
    const [resultsSettings, setResultsSettings] = useState({
        showSettings: false,
        qty: 4,
        showFees: false,
        showOptionalFees: [],
        showRoadHazard: false,
        showAlignment: false,
    });
    const [initialTires, setInitialTires] = useState([]);
    const [tires, setTires] = useState([]);
    const [startingAt, setStartingAt] = useState('');
    const [sortBy, setSortBy] = useState('htl');
    const [showOutOfStock, setShowOutOfStock] = useState(false);
    const [tireSize, setTireSize] = useState('');
    const [searchInput, setSearchInput] = useState('');
    const [search, setSearch] = useState('')
    const [minWarranty, setMinWarranty] = useState(0);
    const [minLoadIndex, setMinLoadIndex] = useState(0);
    const [minSpeedRating, setMinSpeedRating] = useState("L")
    const speedRatings = new Map([
        ["L", 1],
        ["M", 2],
        ["N", 3],
        ["P", 4],
        ["Q", 5],
        ["R", 6],
        ["S", 7],
        ["T", 8],
        ["U", 9],
        ["H", 10],
        ["V", 11],
        ["W", 12],
        ["Y", 13],
        ["Z", 14]
    ])
    const [showProgressiveFilters, setShowProgressiveFilters] = useState(false)
    const [activeFilters, setActiveFilters] = useState({
        category: [],
        brand: [],
        oem: []
    })
    const tireTypes = [];
    initialTires.map(tire => {
        if (tireTypes.indexOf(tire.tire.category) === -1) {
            tireTypes.push(tire.tire.category);
        }
        return tire;
    })
    const tireBrands = [];
    initialTires.map(tire => {
        if (tireBrands.indexOf(tire.tire.brand) === -1) {
            tireBrands.push(tire.tire.brand);
        }
        return tire;
    })
    const tireOEMs = [];
    initialTires.map(tire => {
        if (tireOEMs.indexOf(tire.tire.oem) === -1 && tire.tire.oem !== '') {
            tireOEMs.push(tire.tire.oem);
        }
        return tire;
    })
    tireTypes.sort();
    tireBrands.sort();
    tireOEMs.sort();

    const onShowOptionalFees = (feeId) => {
        if (resultsSettings['showOptionalFees'].indexOf(feeId) === -1) {
            setResultsSettings({ ...resultsSettings, ...resultsSettings['showOptionalFees'].push(feeId) });
        } else {
            setResultsSettings({ ...resultsSettings, showOptionalFees: resultsSettings['showOptionalFees'].filter(value => value !== feeId) })
        }
    }

    const onFilter = (e, filterType) => {
        if (activeFilters[filterType].indexOf(e.target.value) === -1) {
            setActiveFilters({ ...activeFilters, ...activeFilters[filterType].push(e.target.value) });
        } else {
            setActiveFilters({ ...activeFilters, [filterType]: activeFilters[filterType].filter(value => value !== e.target.value) })
        }
    }

    // checkbox filters
    const applyFilters = useCallback(tires => {
        return tires.filter(tire => {
            let filters = false;
            for (let key in activeFilters) {
                if (activeFilters[key].length > 0) {
                    filters = true;
                }
            }
            if (filters) {
                let pass = false;
                for (let key in activeFilters) {
                    if (activeFilters[key].length > 0) {
                        if (activeFilters[key].includes(tire.tire[key])) {
                            pass = true;
                        } else {
                            pass = false;
                            return false;
                        }
                    } else {
                        continue;
                    }
                }
                return pass;
            } else {
                return tire;
            }
        })
    }, [activeFilters])

    // Features filter is separate because they're all separate properties on a tire
    const [featureFilter, setFeatureFilter] = useState({
        runOnFlat: false,
        foam: false,
        studdable: false
    });

    const onSortBy = e => {
        setSortBy(e.target.value);
        if (sortBy === 'htl') {
            setTires(tires.sort((a, b) => a.retailPrice - b.retailPrice));
        } else if (sortBy === 'lth') {
            setTires(tires.sort((a, b) => b.retailPrice - a.retailPrice));
        }
    }
    const applySort = useCallback(tires => {
        if (sortBy === 'lth') {
            return tires.sort((a, b) => a.retailPrice - b.retailPrice);
        } else if (sortBy === 'htl') {
            return tires.sort((a, b) => b.retailPrice - a.retailPrice);
        }
    }, [sortBy])
    // tire size
    // debounce tireSize func
    const dTireSize = size => {
        setTireSize(size);
    }
    // eslint-disable-next-line
    const debounceTireSize = useCallback(debounce(dTireSize, 1000), []);
    const applySizeFilter = useCallback(tires => {
        return tires.filter(tire => tire.tire.size.toLowerCase().includes(tireSize.toLowerCase()))
    }, [tireSize])

    // search
    const updateSearch = term => {
        setSearch(term);
    }
    // eslint-disable-next-line
    const debounceSearch = useCallback(debounce(updateSearch, 1000), []);

    const applySearchFilter = useCallback(tires => {
        // set up regex to filter numbers only or alpha-numeric depending on the search term
        let re;
        if (search.match(/[0-9]+$/g)) {
            re = /[^0-9]/g;
        } else {
            re = /[^a-zA-Z0-9]/g;
        }
        return tires.filter(tire => tire.tire.name.replace(re, '').toLowerCase().includes(search.replace(re, '').toLowerCase()) || tire.tire.size.replace(re, '').toLowerCase().includes(search.replace(re, '').toLowerCase()) || tire.tire.productCode.replace(re, '').toLowerCase().includes(search.replace(re, '').toLowerCase()))
    }, [search])
    // features

    const applyROFFilter = useCallback(tires => {
        if (featureFilter.runOnFlat) {
            return tires.filter(tire => tire.tire.runOnFlat === true)
        } else {
            return tires;
        }
    }, [featureFilter])
    const applyFoamFilter = useCallback(tires => {
        if (featureFilter.foam) {
            return tires.filter(tire => tire.tire.foam === true)
        } else {
            return tires;
        }
    }, [featureFilter])
    const applyStuddableFilter = useCallback(tires => {
        if (featureFilter.studdable) {
            return tires.filter(tire => tire.tire.studdable === true)
        } else {
            return tires;
        }
    }, [featureFilter])
    // warranty
    const onWarranty = e => {
        setMinWarranty(e.target.value);
    }
    const applyWarrantyFilter = useCallback(tires => {
        return tires.filter(tire => parseInt(tire.tire.warranty) >= minWarranty || tire.tire.warranty === 'N/A');
    }, [minWarranty])
    // load index
    const onLoadIndex = e => {
        setMinLoadIndex(e.target.value);
    }
    const applyLoadIndexFilter = useCallback(tires => {
        return tires.filter(tire => parseInt(tire.tire.loadIndex) >= minLoadIndex || tire.tire.loadIndex === 'N/A');
    }, [minLoadIndex])
    // speed rating
    const onSpeedRating = e => {
        setMinSpeedRating(e.target.value);
    }
    const applySpeedRatingFilter = useCallback(tires => {
        return tires.filter(tire => speedRatings.get(tire.tire.speedRating) >= speedRatings.get(minSpeedRating) || tire.tire.speedRating === 'N/A');
        // eslint-disable-next-line
    }, [minSpeedRating])
    // out of stock
    const onShowOutOfStock = () => {
        setShowOutOfStock(!showOutOfStock);
    }
    const applyOutOfStockFilter = useCallback(tires => {
        if (!showOutOfStock) {
            return tires.filter(tire => tire.quantityAvailable > 0 || tire.quantityOnHand > 0)
        } else {
            return tires;
        }
    }, [showOutOfStock])
    const applyTireRankSort = tires => {
        let rankedTires = tires.filter(tire => (tire.adjustment && tire.adjustment.rank !== null));
        let unrankedTires = tires.filter(tire => (!tire.adjustment || (tire.adjustment && tire.adjustment.rank === null)));
        rankedTires.sort((a, b) => a.adjustment.rank - b.adjustment.rank);
        unrankedTires.unshift(...rankedTires);
        return unrankedTires;
    }
    const [progFilterSize, setProgFilterSize] = useState('');
    const applyProgressiveFilter = useCallback(tires => {
        if (progFilterSize.length > 0) {
            return tires.filter(tire => progFilterSize.includes(tire.tire.size));
        } else {
            return tires;
        }
    }, [progFilterSize]);
    const onProgressiveFilter = () => {
        setShowProgressiveFilters(!showProgressiveFilters);
    }

    useEffect(() => {
        // apply filters
        let results = applyFilters(initialTires);
        // apply progressive filter tire sizes
        results = applyProgressiveFilter(results);
        // apply features
        results = applyROFFilter(results);
        results = applyFoamFilter(results);
        results = applyStuddableFilter(results);
        //// apply search
        results = applySearchFilter(results);
        //// apply mileage warranty
        results = applyWarrantyFilter(results);
        //// apply load index
        results = applyLoadIndexFilter(results);
        //// apply speed rating
        results = applySpeedRatingFilter(results);
        //// apply size filter
        results = applySizeFilter(results);
        //// apply out of stock
        results = applyOutOfStockFilter(results);
        //// apply sort
        results = applySort(results);
        // apply tire rank
        results = applyTireRankSort(results);
        setTires(results);

    }, [activeFilters, featureFilter, search, minWarranty, initialTires, applyFilters, applyROFFilter, applyFoamFilter, applyStuddableFilter, applySearchFilter, applyWarrantyFilter, applySizeFilter, applyLoadIndexFilter, applySpeedRatingFilter, applyOutOfStockFilter, applySort, applyProgressiveFilter])

    const getTires = useCallback(async () => {
        try {
            setIsLoading(true);
            const { data } = await axios.get(`/api/v1/recommendedtires?dealer=${selectedStore}`);
            setInitialTires(data.data);
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            console.error(err);
            toast({
                title: `Error`,
                description: `${err.response.data.errorMessages}`,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }, [toast, selectedStore])

    const getFees = useCallback(async () => {
        try {
            const { data } = await axios.get(`/api/v1/fee/${selectedStore}`);
            setFees({
                optional: data.data.Optional,
                required: data.data.Required
            });
        } catch (err) {
            console.error(err);
            toast({
                title: `Error`,
                description: `${err.response.data.errorMessages}`,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }, [toast, selectedStore])

    const getStoreSettings = useCallback(async () => {
        try {
            const { data } = await axios.get(`/api/v1/store-settings/${selectedStore}`);
            setStoreSettings(data.data);
        } catch (err) {
            console.error(err)
            toast({
                title: `Error`,
                description: `${err.response.data.errorMessages}`,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }, [toast, selectedStore])
    
    const getCompanyDealers = useCallback(async () => {
        try {
            const { data } = await axios.get(`/api/v1/store/get`);
            setStores(data.data)
            if (data.data.length === 1) {
                setSelectedStore(data.data[0].dealerId)
            }
        } catch (err) {
            console.error(err.message);
        }
    }, [setSelectedStore])

    const updateSelectedStore = useCallback(async (val) => {
        setSelectedStore(val)
    }, [setSelectedStore])

    useEffect(() => {
        getCompanyDealers();
    }, [getCompanyDealers]);

    useEffect(() => {
        if (selectedStore !== "") {
            getTires()
            getFees()
            getStoreSettings()
        }
    }, [selectedStore, getTires, getFees, getStoreSettings]);

    useEffect(() => {
        // hide out of stock on initial load
        let results = applyOutOfStockFilter(initialTires);
        setTires(results);
        // eslint-disable-next-line
    }, [initialTires]);

    // get starting at price
    useEffect(() => {
        if (tires.length > 0) {
            setStartingAt(([...tires].sort((a, b) => a.retailPrice - b.retailPrice).find(tire => tire.retailPrice).retailPrice));
        }
    }, [tires]);


    return (
        <div className="flex h-full">
            <Accordion allowMultiple>
                <ul className={`${storeView ? 'bg-gray-light' : 'bg-blue-pale'} p-3 h-full max-w-[190px]`}>
                    <li className="flex flex-col mb-2">
                        <AccordionItem border="none">
                            <h6 className="text-[13px] mb-2">
                                <AccordionButton whiteSpace="nowrap" display="flex" justifyContent="space-between">
                                    TYPE/SEASON: <AccordionIcon />
                                </AccordionButton>
                            </h6>
                            <AccordionPanel p="0">
                                {tireTypes.map((category, index) =>
                                    <label className="flex items-center font-sans-reg text-gray-500 text-sm pl-4 mb-2" key={index}>
                                        <input type="checkbox" className="mr-2" value={category} onChange={e => onFilter(e, 'category')} />
                                        {category}
                                    </label>
                                )}
                            </AccordionPanel>
                        </AccordionItem>
                    </li>
                    <li className="flex flex-col mb-2">
                        <AccordionItem border="none">
                            <h6 className="text-[13px] mb-2">
                                <AccordionButton whiteSpace="nowrap" display="flex" justifyContent="space-between">
                                    BRAND: <AccordionIcon />
                                </AccordionButton>
                            </h6>
                            <AccordionPanel p="0">
                                {tireBrands.map((brand, index) =>
                                    <label className="flex items-center font-sans-reg text-gray-500 text-sm pl-4 mb-2" key={index}>
                                        <input type="checkbox" className="mr-2" value={brand} onChange={e => onFilter(e, 'brand')} />
                                        {brand}
                                    </label>
                                )}
                            </AccordionPanel>
                        </AccordionItem>
                    </li>
                    <li className="flex flex-col mb-2">
                        <AccordionItem border="none">
                            <h6 className="text-[13px] mb-2">
                                <AccordionButton whiteSpace="nowrap" display="flex" justifyContent="space-between">
                                    OEM: <AccordionIcon />
                                </AccordionButton>
                            </h6>
                            <AccordionPanel p="0">
                                {tireOEMs.map((oem, index) =>
                                    <label className="flex items-center font-sans-reg text-gray-500 text-sm pl-4 mb-2" key={index}>
                                        <input type="checkbox" className="mr-2" value={oem} onChange={e => onFilter(e, 'oem')} />
                                        {oem}
                                    </label>
                                )}
                            </AccordionPanel>
                        </AccordionItem>
                    </li>
                    <li className="flex flex-col mb-2">
                        <AccordionItem border="none">
                            <h6 className="text-[13px] mb-2">
                                <AccordionButton whiteSpace="nowrap" display="flex" justifyContent="space-between">
                                    FEATURES: <AccordionIcon />
                                </AccordionButton>
                            </h6>
                            <AccordionPanel p="0">
                                <label className="flex items-center font-sans-reg text-gray-500 text-sm pl-4 mb-2">
                                    <input type="checkbox" className="mr-2" value={featureFilter.runOnFlat} onChange={() => setFeatureFilter({ ...featureFilter, runOnFlat: !featureFilter.runOnFlat })} />
                                    Run on Flat
                                </label>
                                <label className="flex items-center font-sans-reg text-gray-500 text-sm pl-4 mb-2">
                                    <input type="checkbox" className="mr-2" value={featureFilter.foam} onChange={() => setFeatureFilter({ ...featureFilter, foam: !featureFilter.foam })} />
                                    Foam
                                </label>
                                <label className="flex items-center font-sans-reg text-gray-500 text-sm pl-4 mb-2">
                                    <input type="checkbox" className="mr-2" value={featureFilter.studdable} onChange={() => setFeatureFilter({ ...featureFilter, studdable: !featureFilter.studdable })} />
                                    Studdable
                                </label>
                            </AccordionPanel>
                        </AccordionItem>
                    </li>
                    <li className="flex flex-col mb-2">
                        <AccordionItem border="none">
                            <h6 className="text-[13px] mb-2">
                                <AccordionButton whiteSpace="nowrap" display="flex" justifyContent="space-between">
                                    WARRANTY: <AccordionIcon />
                                </AccordionButton>
                            </h6>
                            <AccordionPanel>
                                <label className="flex items-center font-sans-reg text-gray-500 text-sm pl-4 mb-2">
                                    {minWarranty} Miles
                                </label>
                                <input type="range" min="0" max="100000" step="10000" className="mr-2" value={minWarranty} onChange={onWarranty} />
                            </AccordionPanel>
                        </AccordionItem>
                    </li>
                    <li className="flex flex-col mb-2">
                        <AccordionItem border="none">
                            <h6 className="text-[13px] mb-2">
                                <AccordionButton whiteSpace="nowrap" display="flex" justifyContent="space-between">
                                    SPEED RATING: <AccordionIcon />
                                </AccordionButton>
                            </h6>
                            <AccordionPanel p="0">
                                <select value={minSpeedRating} onChange={onSpeedRating} className="block mx-auto">
                                    {[...speedRatings.keys()].map(key =>
                                        <option value={key} key={key}>{key}</option>
                                    )}
                                </select>
                            </AccordionPanel>
                        </AccordionItem>
                    </li>
                    <li className="flex flex-col mb-2">
                        <AccordionItem border="none">
                            <h6 className="text-[13px] mb-2">
                                <AccordionButton whiteSpace="nowrap" display="flex" justifyContent="space-between">
                                    LOAD INDEX: <AccordionIcon />
                                </AccordionButton>
                            </h6>
                            <AccordionPanel p="0">
                                <label className="flex items-center font-sans-reg text-gray-500 text-sm pl-4 mb-2">
                                    Min. Load Index: {minLoadIndex}
                                </label>
                                <input type="range" min="0" max="150" step="1" className="mr-2" value={minLoadIndex} onChange={onLoadIndex} />
                            </AccordionPanel>
                        </AccordionItem>
                    </li>
                    <li className="flex flex-col mb-2">
                        <AccordionItem border="none">
                            <h6 className="text-[13px] mb-2">
                                <AccordionButton whiteSpace="nowrap" display="flex" justifyContent="space-between">
                                    OUT OF STOCK: <AccordionIcon />
                                </AccordionButton>
                            </h6>
                            <AccordionPanel p="0">
                                <label className="flex items-center font-sans-reg text-gray-500 text-sm pl-4 mb-2">
                                    <input type="checkbox" className="mr-2" value={showOutOfStock} onChange={onShowOutOfStock} />
                                    Show Out of Stock
                                </label>
                            </AccordionPanel>
                        </AccordionItem>
                    </li>
                </ul>
            </Accordion>
            <div className="relative w-screen">
                {storeView === true &&
                    <InsideHeader heading="Tire Recommendations" imageSrc="/hero-tire_rec.png" imageSrcSet="/hero-tire_rec.png, /hero-tire_rec@2x.png 2x" imageAlt="Tires on racks" />
                }
                {stores.length > 1 && storeView === true && 
                    <div className="p-6">
                        <h3 className="font-sans-reg mb-7">SELECT YOUR STORE:</h3>
                        <label className="flex items-center">
                            <select id="type" onChange={e => updateSelectedStore(e.target.value)} value={selectedStore}>
                                <option value="" key="select-store">Select a Store</option>
                                {stores.map((store, index) =>
                                    <option value={store.dealerId} key={store.dealerId}>{store.dealer}</option>
                                )}
                            </select>
                        </label>
                    </div>
                }
                {resultsSettings.showSettings === true && selectedStore !== "" &&
                    <div className="absolute top-0 left-0 z-20 w-[97%] bg-white m-4 px-4 py-8 border border-gray rounded">
                        <div className="flex justify-between w-full mb-8">
                            <h2 className="text-4xl">Results Settings</h2>
                            <button className="btn-blue" onClick={() => setResultsSettings({ ...resultsSettings, showSettings: false })}><FontAwesomeIcon icon={faTimes} /></button>
                        </div>
                        <div className="flex flex-col">
                            <div className="flex items-center mb-4">
                                <label className="text-2xl mr-8">Select tire quantity</label>
                                <input type="number" name="tireQty" min="1" value={resultsSettings.qty} onChange={e => setResultsSettings({ ...resultsSettings, qty: e.target.value })} className="w-[90px]" />
                            </div>
                            <div className="mb-4">
                                <label className="flex items-center mb-2">
                                    <input type="checkbox" name="showFees" checked={resultsSettings.showFees} onChange={() => setResultsSettings({ ...resultsSettings, showFees: !resultsSettings.showFees })} className="mr-2" />
                                    Include Store Fees
                                </label>
                                <h4 className="font-sans-reg text-sm mb-2">Select Optional Purchase Fees</h4>
                                {fees.optional.map((fee, index) => (
                                    <label className="flex items-center mb-2" key={index}>
                                        <input type="checkbox" checked={resultsSettings.showOptionalFees[fee.feeId]} onChange={() => onShowOptionalFees(fee.feeId)} className="mr-2" />
                                        Include {fee.fee}
                                    </label>
                                ))}
                            </div>
                        </div>
                    </div>
                }
                {selectedStore !== "" &&
                    <div className="p-6">
                        {/* Search and sort */}
                        <div className="bg-blue-pale p-3 flex items-center space-between">
                            <div className="flex">
                                <input type="text" className="form-input border border-gray shadow-inner w-[275px] mr-2" placeholder="Enter a keyword to search" value={searchInput} onChange={e => { setSearchInput(e.target.value); debounceSearch(e.target.value) }} />
                                {/*<button className="btn-blue text-[18px]" onClick={onSearch}><FontAwesomeIcon icon={faSearch} className="mr-1" />Search</button>*/}
                            </div>
                            <div className="flex ml-4">
                                <button className="btn-blue text-[18px]" onClick={onProgressiveFilter}><FontAwesomeIcon icon={faCar} className="mr-1" />Find Tires</button>
                            </div>
                            <div className="flex ml-auto">
                                <select id="sort" onChange={onSortBy}>
                                    <option defaultValue disabled>Sort by Price</option>
                                    <option value="htl">Price: High to Low</option>
                                    <option value="lth">Price: Low to High</option>
                                </select>
                            </div>
                        </div>
                        {/* Progressive Filter */}
                        {showProgressiveFilters &&
                            <ProgressiveFilters tireSize={setProgFilterSize} />
                        }
                        {/* Results */}
                        <div>
                            <div className="flex pl-6 py-4">
                                <p className="font-sans-reg text-xs mr-6"><span className="font-bold mr-1">Tires starting at:</span>${startingAt}</p>
                                <p className="font-sans-reg text-xs"><span className="font-bold mr-1">Results:</span>{tires.length}</p>
                                {storeView === false &&
                                    <div className="flex justify-end ml-auto mb-2">
                                        <button className="btn" onClick={() => setResultsSettings({ ...resultsSettings, showSettings: true })}>Quantity</button>
                                    </div>
                                }
                            </div>
                            {isLoading
                                ?
                                <span className="text-lg">Loading ...</span>
                                :
                                <div className="h-screen">
                                    <AutoSizer>
                                        {({ height, width }) => (
                                            <List
                                                height={height}
                                                itemData={tires}
                                                itemCount={tires.length}
                                                itemSize={storeView ? 320 : (resultsSettings.showFees ? 40 : 0) + (resultsSettings.showOptionalFees.length > 0 ? resultsSettings.showOptionalFees.length * 48 : 0) + 340}
                                                width={width}
                                            >
                                                {({ data, index, style }) => {
                                                    return <div className="mb-2 pl-6" style={style}>
                                                        <TireCard
                                                            tireImage={data[index].imageUrl}
                                                            tireBrand={data[index].tire.brand}
                                                            tireName={data[index].tire.name}
                                                            tireNumber={data[index].tire.productCode}
                                                            tireSize={data[index].tire.size}
                                                            tireType={data[index].tire.category}
                                                            UTQG={data[index].tire.utqg}
                                                            speedRating={data[index].tire.speedRating}
                                                            loadIndex={data[index].tire.loadIndex}
                                                            loadRange={data[index].tire.loadRange}
                                                            sidewall={data[index].tire.sidewall}
                                                            warranty={data[index].tire.warranty}
                                                            runOnFlat={data[index].tire.runOnFlat}
                                                            foam={data[index].tire.foam}
                                                            oem={data[index].tire.oem}
                                                            studdable={data[index].tire.studdable}
                                                            dealerCost={data[index].dealerCost}
                                                            mapPrice={data[index].mapPrice}
                                                            pmpPrice={data[index].pmpPrice}
                                                            retail={data[index].retailPrice}
                                                            invoiceGPDollars={data[index].grossProfitAmount}
                                                            invoiceGPPercent={data[index].grossProfitPercent}
                                                            quantityAvailable={data[index].quantityAvailable}
                                                            quantityOnHand={data[index].quantityOnHand}
                                                            storeView={storeView}
                                                            fees={fees}
                                                            taxRate={storeSettings.taxRate}
                                                            salesView={storeSettings.salesView}
                                                            showCost={storeSettings.showCost}
                                                            resultsSettings={resultsSettings}
                                                            rank={data[index].adjustment ? data[index].adjustment.rank : null}
                                                            key={index} />
                                                    </div>
                                                }}
                                            </List>
                                        )}
                                    </AutoSizer>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        </div >
    );
}