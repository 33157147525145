import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useToast } from '@chakra-ui/react';
import { debounce } from 'lodash';
import axios from 'axios';

export default function StoreAccordion(props) {
    const dealerId = props.storeNumber;
    const toast = useToast();
    const [isSaving, setIsSaving] = useState(false);
    const [showContent, setShowContent] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [contentLoaded, setContentLoaded] = useState(false);
    const [settingsLoaded, setSettingsLoaded] = useState(false);
    const [feesLoaded, setFeesLoaded] = useState(false);
    const [storeSettings, setStoreSettings] = useState({});
    const [fees, setFees] = useState({
        optional: [],
        required: []
    });
    const [newFee, setNewFee] = useState({
        name: '',
        amount: 0,
        percent: 0,
        valueType: 'amount',
        isEditing: false,
        taxable: false,
    })
    const [newOptFee, setNewOptFee] = useState({
        name: '',
        amount: 0,
        percent: 0,
        valueType: 'amount',
        isEditing: false,
        taxable: false,
    })
    const updateNewFeeName = val => {
        setNewFee({
            ...newFee,
            name: val
        })
    }
    const updateNewFeeAmount = val => {
        const validated = val.match(/^(\d*\.{0,1}\d{0,2}$)/)
        if (validated) {
            setNewFee({
                ...newFee,
                amount: val
            })
        }
    }
    const updateNewOptFeeName = val => {
        setNewOptFee({
            ...newOptFee,
            name: val
        })
    }
    const updateNewOptFeeAmount = val => {
        const validated = val.match(/^(\d*\.{0,1}\d{0,2}$)/)
        if (validated) {
            setNewOptFee({
                ...newOptFee,
                amount: val
            })
        }
    }
    const addFee = type => {
        if (type === 'required' && newFee.name !== '' && newFee.amount > 0) {
            createFee(type);
            setNewFee({
                id: '',
                name: '',
                amount: ''
            })
        } else if (type === 'optional' && newOptFee.name !== '' && newOptFee.amount > 0) {
            createFee(type);
            setNewOptFee({
                id: '',
                name: '',
                amount: ''
            })
        }
    }

    const removeFee = async id => {
        try {
            await axios.delete(`/api/v1/fee/${id}`);
            getFees();
            toast({
                title: `Fee Deleted`,
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
        } catch (err) {
            console.error(err)
            toast({
                title: `Error`,
                description: `${err.response.data.errorMessages}`,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }
    const editFee = id => {
        setFees({
            required:
                fees.required.map(fee => {
                    if (fee.feeId === id) {
                        fee.isEditing = !fee.isEditing;
                        // set valueType based on ammount/percent value
                        fee.amount === 0 ? fee.valueType = 'percent' : fee.valueType = 'amount';
                    }
                    return fee;
                }),
            optional:
                fees.optional.map(fee => {
                    if (fee.feeId === id) {
                        fee.isEditing = !fee.isEditing;
                        // set valueType based on ammount/percent value
                        fee.amount === 0 ? fee.valueType = 'percent' : fee.valueType = 'amount';
                    }
                    return fee;
                }),
        })
    }
    const saveFee = async (id, type) => {
        try {
            let updatedFee;
            if (type === 'required') {
                updatedFee = fees.required.find(fee => fee.feeId === id);
            } else {
                updatedFee = fees.optional.find(fee => fee.feeId === id);
            }
            await axios.put(`/api/v1/fee/${id}`, {
                Fee: updatedFee.fee,
                DealerId: updatedFee.dealerId,
                Amount: updatedFee.amount,
                Percent: updatedFee.percent,
                Taxable: updatedFee.taxable,
                Optional: type === 'required' ? false : true,
                PerTire: type === 'required' ? false : updatedFee.perTire
            },
                {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8'
                    },
                });
            //console.log(data)
            setFees({
                required:
                    fees.required.map(fee => {
                        if (fee.feeId === id) {
                            fee.isEditing = !fee.isEditing;
                        }
                        return fee;
                    }),
                optional:
                    fees.optional.map(fee => {
                        if (fee.feeId === id) {
                            fee.isEditing = !fee.isEditing;
                        }
                        return fee;
                    }),
            })
            toast({
                title: `Fee Updated`,
                status: 'info',
                duration: 9000,
                isClosable: true,
            })
        } catch (err) {
            console.error(err);
            toast({
                title: `Error`,
                description: `${err.response.data.errorMessages}`,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }

    const updateFeeName = (id, val) => {
        setFees({
            required:
                fees.required.map(fee => {
                    if (fee.feeId === id) {
                        fee.fee = val;
                    }
                    return fee;
                }),
            optional:
                fees.optional.map(fee => {
                    if (fee.feeId === id) {
                        fee.fee = val;
                    }
                    return fee;
                }),
        })
    }
    const updateFeeAmount = (id, val) => {
        const validated = val.match(/^(\d*\.{0,1}\d{0,2}$)/)
        if (validated) {
            setFees({
                required:
                    fees.required.map(fee => {
                        if (fee.feeId === id) {
                            fee.valueType === 'amount' ? fee.amount = val : fee.percent = val;
                        }
                        return fee;
                    }),
                optional:
                    fees.optional.map(fee => {
                        if (fee.feeId === id) {
                            fee.valueType === 'amount' ? fee.amount = val : fee.percent = val;
                        }
                        return fee;
                    })
            })
        }
    }
    const updateFeeValueType = (id, type) => {
        setFees({
            required:
                fees.required.map(fee => {
                    if (fee.feeId === id) {
                        fee.valueType = type;
                        type === 'amount' ? fee.percent = 0 : fee.amount = 0;
                    }
                    return fee;
                }),
            optional:
                fees.optional.map(fee => {
                    if (fee.feeId === id) {
                        fee.valueType = type;
                        type === 'amount' ? fee.percent = 0 : fee.amount = 0;
                    }
                    return fee;
                })
        })
    }
    const updateFeeTaxable = id => {
        setFees({
            required:
                fees.required.map(fee => {
                    if (fee.feeId === id) {
                        fee.taxable = !fee.taxable;
                    }
                    return fee;
                }),
            optional:
                fees.optional.map(fee => {
                    if (fee.feeId === id) {
                        fee.taxable = !fee.taxable;
                    }
                    return fee;
                }),
        })
    }
    const updateFeePerTire = id => {
        setFees({
            required:
                fees.required.map(fee => {
                    if (fee.feeId === id) {
                        fee.perTire = !fee.perTire;
                    }
                    return fee;
                }),
            optional:
                fees.optional.map(fee => {
                    if (fee.feeId === id) {
                        fee.perTire = !fee.perTire;
                    }
                    return fee;
                }),
        })
    }

    const getFees = useCallback(async () => {
        try {
            const { data } = await axios.get(`/api/v1/fee/${dealerId}`);
            setFees({
                optional: data.data.Optional,
                required: data.data.Required
            });
            setFeesLoaded(true);
            if (settingsLoaded) {
                setContentLoaded(true);
            }
            //console.log(data.data);
        } catch (err) {
            console.error(err);
            toast({
                title: `Error`,
                description: `${err.response.data.errorMessages}`,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }, [toast, dealerId, settingsLoaded, setFeesLoaded])

    const createFee = useCallback(async type => {
        try {
            await axios.post('/api/v1/fee',
                {
                    Fee: type === 'required' ? newFee.name : newOptFee.name,
                    Amount: type === 'required' ? newFee.amount : newOptFee.amount,
                    DealerId: dealerId,
                    Optional: type === 'optional' ? true : false
                },
                {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8'
                    },
                }
            )
            getFees();
            //console.log(data)
            toast({
                title: `New Fee Created`,
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
        } catch (err) {
            console.error(err);
            toast({
                title: `Error`,
                description: `${err.response.data.errorMessages}`,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }, [getFees, newFee.amount, newFee.name, newOptFee.name, newOptFee.amount, toast, dealerId])

    const getStoreSettings = useCallback(async () => {
        try {
            const { data } = await axios.get(`/api/v1/store-settings/${dealerId}`);
            setStoreSettings(data.data)
            setSettingsLoaded(true);
            if (feesLoaded) {
                setContentLoaded(true);
            }
            console.log(data.data);
        } catch (err) {
            console.error(err.message)
            toast({
                title: `Error`,
                description: `${err.response.data.errorMessages}`,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }, [toast, dealerId, setSettingsLoaded, feesLoaded])

    const updateStoreSettings = async () => {
        try {
            setIsSaving(true);
            //console.log(storeSettings.warehouseId)
            await axios.put(`/api/v1/store-settings/${dealerId}`, {
                Dealer: storeSettings.dealer,
                SalesView: storeSettings.salesView,
                ShowCost: storeSettings.showCost,
                TaxRate: storeSettings.taxRate,
                WarehouseId: storeSettings.warehouseId ?? "",
            }, {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8'
                }
            });
            setIsSaving(false);
            getStoreSettings();
            props.getStores();
            toast({
                title: `Store Settings Saved`,
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
        } catch (err) {
            console.error(err.message);
            setIsSaving(false);
            for (const [msg, error] of Object.entries(err.response.data.errors)) {
                toast({
                    title: `Error`,
                    description: `${msg}: ${error}`,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }
        }
    }

    const deleteStore = async () => {
        try {
            setIsSaving(true);
            await axios.delete(`/api/v1/store-settings/delete/${dealerId}`);
            setIsSaving(false);
            setShowDeleteModal(false);
            setShowContent(false);
            props.getStores();
            toast({
                title: `Store Deleted!`,
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
        } catch (err) {
            console.error(err.message);
            setIsSaving(false);
            toast({
                title: `Error`,
                description: `${err.response.data.errorMessages}`,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }

    const updateTaxRate = async (salesView, showCost, warehouseId, taxRate) => {
        try {
            await axios.put(`/api/v1/store-settings/${dealerId}`, {
                SalesView: salesView,
                ShowCost: showCost,
                TaxRate: taxRate,
                WarehouseId: warehouseId ?? ""
            }, {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8'
                }
            });
            getStoreSettings();
            toast({
                title: `Tax Rate Updated`,
                status: 'info',
                duration: 9000,
                isClosable: true,
            })
        } catch (err) {
            console.error(err.message);
            toast({
                title: `Error`,
                description: `${err.response.data.errorMessages}`,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }

    // eslint-disable-next-line
    const debounceTaxRate = useCallback(debounce(updateTaxRate, 3000), []);

    const onSelectDistributor = async (e) => {
        setStoreSettings({ ...storeSettings, warehouseId: e.target.value });
        try {
            setIsSaving(true);
            // console.log(storeSettings.warehouseId)
            await axios.put(`/api/v1/store-settings/${dealerId}`, {
                SalesView: storeSettings.salesView,
                ShowCost: storeSettings.showCost,
                TaxRate: storeSettings.taxRate,
                WarehouseId: e.target.value,
            }, {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8'
                }
            });
            setIsSaving(false);
            getStoreSettings();
            toast({
                title: `Store Settings Saved`,
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
        } catch (err) {
            console.error(err.message);
            setIsSaving(false);
            toast({
                title: `Error`,
                description: `${err.response.data.errorMessages}`,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }

    useEffect(() => {
        getFees();
    }, [getFees])

    useEffect(() => {
        getStoreSettings();
        // eslint-disable-next-line
    }, []);

    return (
        contentLoaded &&
        <div className="mb-2 group">
            <div className={`flex items-center w-full pl-6 pr-2 py-2 rounded ${showContent ? 'bg-blue text-white' : 'group-even:bg-blue-pale'}`}>
                <span className="w-1/3 text-xl">{props.storeName}</span>
                <span className="w-1/3 text-xl">{props.storeNumber}</span>
                <button className="bg-blue flex items-center justify-center text-white text-2xl ml-auto px-4 p-1 w-[42px] h-[42px] rounded" onClick={() => setShowContent(!showContent)}><FontAwesomeIcon icon={!showContent ? faCaretDown : faCaretUp} /></button>
            </div>
            {showContent &&
                <div className="bg-white p-6">
                    <h2 className="text-2xl text-blue font-reg mb-4">Update Store Information</h2>
                    <div className="flex items-center w-full mb-4">
                        <label className="mb-2 mr-2 flex flex-col flex-1 text-sm font-sans-reg font-bold text-gray-dark">STORE NAME:
                            <input type="text" className="form-input border border-gray-300 shadow-inner" value={storeSettings.dealer} onChange={e => setStoreSettings({ ...storeSettings, dealer: e.target.value })} />
                        </label>
                        <button className="btn translate-y-2" onClick={() => updateStoreSettings('required')}>UPDATE</button>
                    </div>
                    {/*distributors*/}
                    <div className="flex items-center mb-8">
                        <label className="font-sans-reg uppercase mr-2">Choose a Distributor:</label>
                        <select value={storeSettings.warehouseId ?? ""} onChange={onSelectDistributor}>
                            <option value="">Select One</option>
                            {Object.keys(props.warehouses).map((key, index) => (
                                <option value={key} key={index}>{props.warehouses[key]}</option>
                            ))}
                        </select>
                    </div>
                    <h2 className="text-2xl text-blue font-reg mb-4">Store Taxes & Fees</h2>
                    {/*tax percentage*/}

                    <div className="flex items-center mb-8">
                        <label className="font-sans-reg uppercase mr-2">Tax Percentage:</label> <input type="number" min="0" step=".01" placeholder="0.00" value={storeSettings.taxRate} onChange={e => { setStoreSettings({ ...storeSettings, taxRate: e.target.value }); debounceTaxRate(storeSettings.salesView, storeSettings.showCost, storeSettings.warehouseId, e.target.value); }} /><span className="ml-1">%</span>
                    </div>
                    {/*store fees*/}
                    <h3 className="text-xl text-blue uppercase font-reg mb-4">Store Fees</h3>
                    <table className="w-full table-fixed text-sm font-sans-reg text-left border-separate border-spacing-y-2.5">
                        <thead className="text-gray-dark">
                            <tr>
                                <th>STORE FEES (PER TIRE)</th>
                                <th>$ AMOUNT PER TIRE</th>
                                <th>TAXABLE</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {fees.required.length === 0 &&
                                <tr><td>There are no fees added</td><td></td></tr>
                            }
                            {fees.required.map(fee =>
                                <tr className="even:bg-white odd:bg-blue-pale" key={fee.feeId}>
                                    <td className="p-2">{!fee.isEditing ? fee.fee : <input type="text" placeholder={fee.fee} onChange={e => updateFeeName(fee.feeId, e.target.value)} />}</td>
                                    <td className="p-2">{!fee.isEditing ? fee.amount : <input type="number" placeholder={fee.amount} onChange={e => updateFeeAmount(fee.feeId, e.target.value)} />}</td>
                                    <td className="p-2"><input type="checkbox" checked={fee.taxable} onChange={() => updateFeeTaxable(fee.feeId)} className={!fee.isEditing ? '' : 'form-input--disabled'} disabled={!fee.isEditing} /></td>
                                    <td className="p-2 text-right">
                                        {!fee.isEditing
                                            ? <button className="btn-blue mr-2" onClick={() => editFee(fee.feeId)}>Edit</button>
                                            : <button className="btn-blue mr-2" onClick={() => saveFee(fee.feeId, 'required')}>Save</button>
                                        }
                                        <button className="btn-blue" onClick={() => removeFee(fee.feeId)}><FontAwesomeIcon icon={faTimes} /></button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    {/*add store fee*/}
                    <h3 className="text-xl text-blue uppercase font-reg mb-4">Add a New Fee</h3>
                    <div className="flex items-center w-full">
                        <label className="mb-2 mr-2 flex flex-col flex-1 text-sm font-sans-reg font-bold text-gray-dark">FEE NAME:
                            <input type="text" className="form-input border border-gray-300 shadow-inner" value={newFee.name} onChange={e => updateNewFeeName(e.target.value)} />
                        </label>
                        <label className="mb-2 mr-2 flex flex-col flex-1 text-sm font-sans-reg font-bold text-gray-dark">$ AMOUNT PER TIRE:
                            <input type="number" className="form-input border border-gray-300 shadow-inner" value={newFee.amount} onChange={e => updateNewFeeAmount(e.target.value)} />
                        </label>
                        <button className="btn translate-y-2" onClick={() => addFee('required')}>CREATE</button>
                    </div>
                    <div className="border border-1 my-6"></div>
                    {/*optional fees*/}
                    <h2 className="text-2xl text-blue font-reg mb-4">Optional Service Fees</h2>
                    <table className="w-full table-auto text-sm font-sans-reg text-left border-separate border-spacing-y-2.5">
                        <thead className="text-gray-dark">
                            <tr>
                                <th>SERVICE FEES (APPLY TO ALL TIRES)</th>
                                <th>$ AMOUNT OR % OF TIRE COST</th>
                                <th></th>
                                <th>TAXABLE</th>
                                <th>PER TIRE</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {fees.optional.length === 0 &&
                                <tr><td>There are no service fees added</td><td></td></tr>
                            }
                            {fees.optional.map(fee =>
                                <tr className="even:bg-white odd:bg-blue-pale" key={fee.feeId}>
                                    <td className="p-2">{!fee.isEditing ? fee.fee : <input type="text" placeholder={fee.fee} onChange={e => updateFeeName(fee.feeId, e.target.value)} />}</td>
                                    <td className="p-2"><div className="flex items-center">{!fee.isEditing ? (fee.amount !== 0 ? '$' + fee.amount : fee.percent + '%') : <input type="number" placeholder={(fee.amount !== 0 ? fee.amount : fee.percent)} onChange={e => updateFeeAmount(fee.feeId, e.target.value)} />}</div></td>
                                    <td className="p-2">{fee.isEditing &&
                                        <div className="flex flex-col">
                                            <label className="flex">
                                                <input type="radio" name="valueType" value="amount" checked={fee.valueType === 'amount'} onChange={() => updateFeeValueType(fee.feeId, 'amount')} />
                                                <span className="ml-1">$</span>
                                            </label>
                                            <label className="flex">
                                                <input type="radio" name="valueType" value="percent" checked={fee.valueType === 'percent'} onChange={() => updateFeeValueType(fee.feeId, 'percent')} />
                                                <span className="ml-1">%</span>
                                            </label>
                                        </div>
                                    }
                                    </td>
                                    <td className="p-2"><input type="checkbox" checked={fee.taxable} onChange={() => updateFeeTaxable(fee.feeId)} className={!fee.isEditing ? '' : 'form-input--disabled'} disabled={!fee.isEditing} /></td>
                                    <td className="p-2"><input type="checkbox" checked={fee.perTire} onChange={() => updateFeePerTire(fee.feeId)} className={!fee.isEditing ? '' : 'form-input--disabled'} disabled={!fee.isEditing} /></td>
                                    <td className="p-2 text-right">
                                        {!fee.isEditing
                                            ? <button className="btn-blue mr-2" onClick={() => editFee(fee.feeId)}>Edit</button>
                                            : <button className="btn-blue mr-2" onClick={() => saveFee(fee.feeId, 'optional')}>Save</button>
                                        }
                                        <button className="btn-blue" onClick={() => removeFee(fee.feeId)}><FontAwesomeIcon icon={faTimes} /></button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    {/*add optional fee*/}
                    <h2 className="text-blue text-2xl mb-4">Add a New Optional Fee</h2>
                    <div className="flex items-center w-full">
                        <label className="mb-2 mr-2 flex flex-col flex-1 text-sm font-sans-reg font-bold text-gray-dark">FEE NAME:
                            <input type="text" className="form-input border border-gray-300 shadow-inner" value={newOptFee.name} onChange={e => updateNewOptFeeName(e.target.value)} />
                        </label>
                        <label className="mb-2 mr-2 flex flex-col flex-1 text-sm font-sans-reg font-bold text-gray-dark">$ AMOUNT PER TIRE:
                            <input type="number" className="form-input border border-gray-300 shadow-inner" value={newOptFee.amount} onChange={e => updateNewOptFeeAmount(e.target.value)} />
                        </label>
                        <button className="btn translate-y-2" onClick={() => addFee('optional')}>CREATE</button>
                    </div>
                    <div className="border border-1 my-6"></div>
                    {/*distrubtor pricing*/}
                    <div className="flex justify-between items-center">
                        <h2 className="text-2xl text-blue font-reg mb-4">File Uploads</h2>
                    </div>
                    {props.files.length === 0 && props.filesWD.length === 0
                        ?
                        <h3>No pricing files have been added.</h3>
                        :
                        <table className="w-full table-auto text-sm font-sans-reg text-left border-separate border-spacing-y-2.5">
                            <thead className="text-gray-dark">
                                <tr>
                                    <th>TYPE</th>
                                    <th>DATE UPLOADED</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.files.map((file, index) =>
                                    <tr className="even:bg-white odd:bg-blue-pale" key={index}>
                                        <td className="px-5 py-4">{file.type}</td>
                                        <td className="px-5 py-4">{file.date}</td>
                                    </tr>
                                )}
                                {props.filesWD.map((file, index) =>
                                    <tr className="even:bg-white odd:bg-blue-pale" key={index}>
                                        <td className="px-5 py-4">{file.type}</td>
                                        <td className="px-5 py-4">{file.date}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    }
                    <div className="border border-1 my-6"></div>
                    {/*sales view*/}
                    <h2 className="text-2xl text-blue mb-8">Sales View (choose one)</h2>
                    <div className="flex items-center">
                        <div className="flex flex-col items-center px-4">
                            <label className="flex items-center text-sm font-sans-reg font-bold text-left text-gray-dark mb-2">
                                <input type="radio" name="salesView" className="mr-1" checked={storeSettings.salesView === 'only-product-price' ? true : false} onChange={() => setStoreSettings({ ...storeSettings, salesView: 'only-product-price' })} />
                                Only show highest profit price
                            </label>
                            <label className="flex items-center text-sm font-sans-reg text-left text-gray-dark">
                                <input type="checkbox" name="showCost" checked={storeSettings.showCost === true && storeSettings.salesView === 'only-product-price' ? true : false} className="mr-1" onChange={() => setStoreSettings({ ...storeSettings, showCost: !storeSettings.showCost })} />
                                (Optional) Show Cost
                            </label>
                        </div>
                        <span className="self-center text-center text-sm font-sans-reg text-left text-gray-dark">OR</span>
                        <div className="flex flex-col items-center px-4">
                            <label className="flex items-center text-sm font-sans-reg font-bold text-left text-gray-dark mb-2">
                                <input type="radio" name="salesView" className="mr-1" checked={storeSettings.salesView === 'show-additional-options' ? true : false} onChange={() => setStoreSettings({ ...storeSettings, salesView: 'show-additional-options' })} />
                                Show additional pricing options (MAP, PMP, Retail)
                            </label>
                            <label className="flex items-center text-sm font-sans-reg text-left text-gray-dark">
                                <input type="checkbox" name="showCost" checked={storeSettings.showCost === true && storeSettings.salesView === 'show-additional-options' ? true : false} className="mr-1" onChange={() => setStoreSettings({ ...storeSettings, showCost: !storeSettings.showCost })} />
                                (Optional) Show Cost
                            </label>
                        </div>
                        <button className="btn block ml-auto" onClick={() => updateStoreSettings()}>{isSaving ? 'Saving ...' : 'Save'}</button>
                    </div>
                    <div className="border border-1 my-6"></div>
                    {/*delete*/}
                    <div className="flex items-center">
                        <div className="flex flex-col">
                            <h2 className="text-2xl text-red-danger mb-1">Delete Store (!)</h2>
                            <p>Choosing this will permanently delete this store without the ability to recover the information.</p>
                        </div>

                        <button className="btn-red block ml-auto" onClick={() => setShowDeleteModal(!showDeleteModal)}>Delete</button>
                    </div>
                    {showDeleteModal &&
                        <div class="bg-[rgba(0,0,0,.75)] absolute top-0 left-0 w-full h-full">
                            <div class="bg-[rgba(255,255,255,.7)] flex flex-col item-center text-center p-2 fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                                <div class="bg-white flex flex-col item-center text-center px-24 py-12">
                                    <p class="mb-2">Are you sure you want to delete this store?</p>
                                    <p class="font-bold mb-6">{props.storeName}</p>
                                    <div class="flex justify-between">
                                        <button class="btn" onClick={() => setShowDeleteModal(!showDeleteModal)}>No</button>
                                        <button class="btn-red" onClick={() => deleteStore()}>Yes, Delete</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    }
                </div>

            }
        </div>
    );
}
