import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useToast } from '@chakra-ui/react';

export default function ProgressiveFilters(props) {
    const toast = useToast();
    const [years, setYears] = useState([]);
    const [makes, setMakes] = useState([]);
    const [models, setModels] = useState([]);
    const [trims, setTrims] = useState([]);
    const [sizes, setSizes] = useState([]);
    // selections
    const [selectYear, setSelectYear] = useState('');
    const [selectMake, setSelectMake] = useState('');
    const [selectModel, setSelectModel] = useState('');
    const [selectTrim, setSelectTrim] = useState('');
    const [selectSize, setSelectSize] = useState('');

    const onSelectYear = e => {
        setSelectYear(e.target.value);
        setSelectMake('');
        setSelectModel('');
        setSelectTrim('');
        setSelectSize('');
    }

    const onSelectMake = e => {
        setSelectMake(e.target.value);
        setSelectModel('');
        setSelectTrim('');
        setSelectSize('');
    }

    const onSelectModel = e => {
        setSelectModel(e.target.value);
        setSelectTrim('');
        setSelectSize('');
    }

    const onSelectTrim = e => {
        setSelectTrim(e.target.value);
        setSelectSize('');
    }

    const getYears = useCallback(async () => {
        setSelectMake('');
        setSelectModel('');
        setSelectTrim('');
        setSelectSize('');
        try {
            const { data } = await axios.get('/api/v1/wheel-size/years');
            setYears(data.data);
        } catch (err) {
            toast({
                title: `Error`,
                description: `${err.response.data.errorMessages}`,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }, [setYears, toast]);

    const getMakes = useCallback(async () => {
        if (selectYear !== '') {
            try {
                const { data } = await axios.get(`/api/v1/wheel-size/makes?year=${selectYear}`);
                setMakes(data.data);
            } catch (err) {
                toast({
                    title: `Error`,
                    description: `${err.response.data.errorMessages}`,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }
        }
    }, [selectYear, toast]);

    const getModels = useCallback(async () => {
        if (selectYear !== '' && selectMake !== '') {
            try {
                const { data } = await axios.get(`/api/v1/wheel-size/models?year=${selectYear}&make=${selectMake}`);
                setModels(data.data);
            } catch (err) {
                toast({
                    title: `Error`,
                    description: `${err.response.data.errorMessages}`,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }
        }
    }, [selectYear, selectMake, toast]);

    const getTrims = useCallback(async () => {
        if (selectYear !== '' && selectMake !== '' && selectModel !== '') {
            try {
                const { data } = await axios.get(`/api/v1/wheel-size/trim?year=${selectYear}&make=${selectMake}&model=${selectModel}`);
                setTrims(data.data);
            } catch (err) {
                toast({
                    title: `Error`,
                    description: `${err.response.data.errorMessages}`,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }
        }
    }, [selectYear, selectMake, selectModel, toast]);

    const getSizes = useCallback(async () => {
        if (selectYear !== '' && selectMake !== '' && selectModel !== '' && selectTrim !== '') {
            try {
                const { data } = await axios.get(`/api/v1/wheel-size/sizes?year=${selectYear}&make=${selectMake}&model=${selectModel}&trim=${selectTrim}`);
                let sizes = [];
                for (const wheel of data.data[0].wheels) {
                    if (wheel.front.tire.length > 0 && !wheel.rear.tire.length > 0) {
                        sizes.push(wheel.front.tire);
                    } else {
                        sizes.push(wheel.front.tire + ' | ' + wheel.rear.tire); // push front/rear together if staggered sizes
                    }
                }
                setSizes(sizes);
            } catch (err) {
                toast({
                    title: `Error`,
                    description: `${err}`,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }
        }
    }, [selectYear, selectMake, selectModel, selectTrim, toast]);

    const resetFilters = () => {
        setSelectYear('');
        setSelectMake('');
        setSelectModel('');
        setSelectTrim('');
        setSelectSize('');
    }

    useEffect(() => {
        getYears();
    }, [getYears])

    useEffect(() => {
        getMakes();
    }, [selectYear, getMakes])

    useEffect(() => {
        getModels();
    }, [selectMake, getModels])

    useEffect(() => {
        getTrims();
    }, [selectModel, getTrims])

    useEffect(() => {
        getSizes();
    }, [selectTrim, getSizes])

    const { tireSize } = props;
    useEffect(() => {
        tireSize(selectSize);
    }, [selectSize, tireSize])

    return (
        <form>
            <div className="bg-blue-pale flex items-end p-4">
                <div className="flex flex-col">
                    <label htmlFor="year">Year</label>
                    <select id="year" name="year" value={selectYear} onChange={onSelectYear}>
                        <option defaultValue>Select Year</option>
                        {years.map((year, index) => (
                            <option value={year.slug} key={index}>{year.name}</option>
                        ))}
                    </select>
                </div>
                <div className="flex flex-col ml-2">
                    <label htmlFor="make">Make</label>
                    <select id="make" name="make" value={selectMake} onChange={onSelectMake}>
                        <option defaultValue>Select Make</option>
                        {makes.map((make, index) => (
                            <option value={make.slug} key={index}>{make.name}</option>
                        ))}
                    </select>
                </div>
                <div className="flex flex-col ml-2">
                    <label htmlFor="model">Model</label>
                    <select id="model" name="model" value={selectModel} onChange={onSelectModel}>
                        <option defaultValue>Select Model</option>
                        {models.map((model, index) => (
                            <option value={model.slug} key={index}>{model.name}</option>
                        ))}
                    </select>
                </div>
                <div className="flex flex-col ml-2">
                    <label htmlFor="trim">Trim</label>
                    <select id="trim" name="trim" value={selectTrim} onChange={onSelectTrim}>
                        <option defaultValue>Select Trim</option>
                        {trims.map((trim, index) => (
                            <option value={trim.slug} key={index}>{trim.name}</option>
                        ))}
                    </select>
                </div>
                <div className="flex flex-col ml-2">
                    <label htmlFor="size">Tire Size <small>(Front | Rear if applicable)</small></label>
                    <select id="size" name="size" value={selectSize} onChange={e => setSelectSize(e.target.value)}>
                        <option defaultValue>Select Size</option>
                        {sizes.map((size, index) => (
                            <option value={size} key={index}>{size}</option>
                        ))}
                    </select>
                </div>
                <button type="button" className="btn ml-2" onClick={resetFilters}>Reset</button>
            </div>
        </form>
    );
}