export const GetApiUrl = () => {
    let apiUrl = `https://${window.location.host}`;

    if (window.location.hostname.includes('www.tireshoppro.com')) {
        apiUrl = 'https://api.tireshoppro.com';
    }
    else if (window.location.hostname.includes('staging.tireshoppro.com')) {
        apiUrl = 'https://staging-api.tireshoppro.com';
    } else if (window.location.hostname.includes('tsnapp-tsnappdev.azurewebsites.net')) {
        apiUrl = 'https://tsnapi-dev.azurewebsites.net';
    }

    return apiUrl;
}

export const GetAuthClientId = () => {
    let authClientId = '0tYzvtfliU9ik8ZsoKCAHSjBGhePmRX4';

    if (window.location.hostname.includes('www.tireshoppro.com')) {
        authClientId = '033gk5iaFnCsdoh3VdZnxVjJsvDdccv3';
    }
    else if (window.location.hostname.includes('staging.tireshoppro.com')) {
        authClientId = 'eIlf8QRau1hUPPe3msn5i75AdRDGJ8UL';
    } else if (window.location.hostname.includes('tsnapp-tsnappdev.azurewebsites.net')) {
        authClientId = '4YLVAN7jCCjSjXWDFkMzkreSNNzw8m0i';
    }

    return authClientId;
}

export const GetAuthDomain = () => {
    let authDomain = 'dev-8uwkj05a7pntrqg2.us.auth0.com';
    if (window.location.hostname.includes('www.tireshoppro.com')) {
        authDomain = 'tireshoppro.us.auth0.com';
    }

    return authDomain;
}

export const GetRollbarConfig = () => {
    let environment = 'local';

    if (window.location.hostname.includes('www.tireshoppro.com')) {
        environment = 'production';
    }
    else if (window.location.hostname.includes('staging.tireshoppro.com')) {
        environment = 'staging';
    } else if (window.location.hostname.includes('tsnapp-tsnappdev.azurewebsites.net')) {
        environment = 'development';
    }

    return {
        accessToken: '6810cd1a211c493cbd44d911cfb5c5dc',
        captureUncaught: true,
        captureUnhandledRejections: true,
        environment: environment
    }
}