import React, { useState, useEffect } from 'react';
import { Outlet, Link, useLocation } from 'react-router-dom';
import ToggleSwitch from '../components/ToggleSwitch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faBars, faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { useAuth0 } from "@auth0/auth0-react";

export default function Root() {
    const [menuOpen, setMenuOpen] = useState(false)
    const [showFilters, setShowFilters] = useState(false);
    const [storeView, setStoreView] = useState(true)
    const location = useLocation();
    const { user, logout } = useAuth0();
    const handleLogout = () => {
        logout({
            logoutParams: {
                returnTo: window.location.origin,
            },
        });
    };

    useEffect(() => {
        const sidebarDisplay = () => {
            if (location.pathname === '/tire-recommendations' || location.pathname === '/price-settings') {
                setMenuOpen(false);
                setShowFilters(true);
            } else {
                setMenuOpen(true);
                setShowFilters(false);
            }
        }
        sidebarDisplay();
    }, [location])

    return (
        <div className="flex flex-col h-screen">
            <header className="flex flex-col">
                <div className={`${storeView ? 'bg-red-light' : 'bg-gray-inactive'}`}>
                    <div className="flex justify-end items-center text-xs text-white uppercase px-4 py-[4px]">
                        {storeView === true &&
                            <span>Store View</span>
                        }
                        <ToggleSwitch checked={storeView} onChange={() => setStoreView(!storeView)} />
                    </div>
                </div>
                {storeView === true &&
                    <div className="bg-blue">
                        <div className="p-4 flex space-between">
                            <img src="./logo-TSN.png" srcSet="./logo-TSN.png, ./logo-TSN@2x.png 2x" alt="Goodyear Tire Service Network" className="w-[120px]" />
                            <div className="flex items-center text-white ml-auto uppercase">
                                {user?.name ?? "Login"} <FontAwesomeIcon icon={faUser} className="ml-2" />
                                <Link onClick={handleLogout}>Log out</Link>
                            </div>
                        </div>
                        <div className="bg-yellow w-full h-[2px] mb-4"></div>
                    </div>
                }
            </header>
            <main className="flex relative flex-1">
                {storeView === true &&
                    <div className={`absolute top-0 left-0 bg-blue-dark text-white flex flex-col ${menuOpen ? "translate-x-0" : "translate-x-[-180px]"} w-[220px] h-full transition`}>
                        {(location.pathname === '/tire-recommendations' || location.pathname === '/price-settings') &&
                            <button className="bg-blue flex flex-col" onClick={() => setMenuOpen(!menuOpen)}>
                                {menuOpen
                                    ? <FontAwesomeIcon icon={faArrowCircleLeft} className="text-2xl p-2 ml-auto" />
                                    : <FontAwesomeIcon icon={faBars} className="text-2xl p-2 ml-auto" />
                                }
                            </button>
                        }
                        <nav className={`${menuOpen ? "block" : "hidden"}`}>
                            <ul className="">
                                <li className="even:bg-blue"><Link to="tire-recommendations" className="block px-4 py-2">Tire Recommendations</Link></li>
                                {user?.custom_roles.includes("Owner") && <li className="even:bg-blue"><Link to="gross-profit-mark-up" className="block px-4 py-2">Gross Profit & Mark-Up</Link></li> }
                                {!user?.custom_roles.includes("Associate") && <li className="even:bg-blue"><Link to="price-settings" className="block px-4 py-2">Price Settings</Link></li>}
                                {!user?.custom_roles.includes("Associate") && <li className="even:bg-blue"><Link to="file-uploads" className="block px-4 py-2">File Uploads</Link></li>}
                                {!user?.custom_roles.includes("Associate") && <li className="even:bg-blue"><Link to="store-settings" className="block px-4 py-2">Store Settings</Link></li>}
                                {!user?.custom_roles.includes("Associate") && <li className="even:bg-blue"><Link to="users" className="block px-4 py-2">Users</Link></li>}
                            </ul>
                        </nav>
                    </div>
                }
                <div className={`${storeView ? 'pl-10' : ''} ${menuOpen ? (!showFilters ? 'pl-[220px]' : '') : ''} w-full overflow-hidden`}>
                    <Outlet context={[storeView, setStoreView, menuOpen, setMenuOpen, showFilters, setShowFilters]} />
                </div>
            </main>
        </div>
    );
}